import { SiteHeader } from "@/routes/site-header";
import { Footer } from "@/routes/footer";
import * as React from "react";
import axios from "axios";
import { API_URL, getRandomImage } from "@/config";
import { LoadingPost } from "@/routes/loading";
import { useNavigate, useLocation } from "react-router-dom";
import { getHeader, getToken, removeAccessToken } from "./utils";

export function MostViews({ news }) {
  return (
    <div className="fbt-bottom-section clearfix py-3" id="fbt_bottom_section">
      <div className="widget PopularPosts">
        <div className="widget-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="title fbt-big-title-text text-center">
                  <span className="main-title">
                    Our Favorites{" "}
                    <span className="title-sec-text">
                      Meet the top articles
                    </span>
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="container-fluid fbt-ppFull-width">
            <div className="row">
              {news &&
                news.map((item, index) => (
                  <div key={item.alias} className="item col-6 col-xl p-0">
                    <div className="post card shadow-none">
                      <div className="fbt-item-thumbnail clearfix">
                        <a href={`/posts/${item.slug}/`}>
                          <img
                            alt=""
                            className="post-thumbnail lazyloaded"
                            data-src="./images/img-11.jpg"
                            src={
                              item.resized_featured_image || getRandomImage()
                            }
                          />
                        </a>
                      </div>
                      <div className="card-img-overlay">
                        <div className="fbt-post-title-content d-table">
                          <h3 className="h5 post-title text-white d-table-cell align-bottom">
                            <a
                              className="text-white"
                              href={`/posts/${item.slug}/`}
                            >
                              {item.title}
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PriceTable({ price_id }) {
  const [plans, setPlans] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loadingPayment, setLoadingPayment] = React.useState("");
  const [topSix, setTopSix] = React.useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  React.useEffect(() => {
    if (loading) return;
    setLoading(true);
    const fetchTopSix = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/top-six/`);
        setTopSix(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopSix();
  }, [plans, location.search]);

  // Function to fetch data
  React.useEffect(() => {
    const fetchSubscriptionList = async () => {
      if (loading) return;
      setLoading(true);
      try {
        const token = getToken();
        const query = useQuery();
        const sessionId = query.get("session_id");

        if (sessionId) {
          setLoadingPayment(true);
          let attempts = 0;
          // Function to call verify payment API
          const verifyPayment = async () => {
            attempts += 1;
            let header = getHeader()
            try {
              const verifyResponse = await axios.get(
                `${API_URL}/api/v1/payments/verify/${sessionId}/`,
                {},
                header
              );

              if (verifyResponse.data.paid === true) {
                let header = getHeader()
                const subscriptionResponse = await axios.post(
                  `${API_URL}/api/v1/payments/subscription/`,
                  {},
                  header
                );
                setLoading(false);
                clearInterval(interval);
                if (subscriptionResponse.data.feeds.length == 0) {
                  navigate("/dashboard/feed/");
                  return;
                }
                if (
                  subscriptionResponse.data.feeds.length >= 1 &&
                  subscriptionResponse.data.has_access &&
                  subscriptionResponse.data.is_completed
                ) {
                  let lpath = localStorage.getItem("ldirect");
                  localStorage.removeItem("ldirect");
                  if (lpath) {
                    navigate(lpath);
                  } else {
                    navigate("/");
                  }
                }
                return;
              }
            } catch (verifyError) {
              console.log(verifyError);
              if (verifyError.response.status == 401) {
                localStorage.setItem("ldirect", window.location.href);
                navigate("/account/");
              }
            }

            if (attempts >= 5) {
              setLoadingPayment(false);
              clearInterval(interval);
              let header = getHeader()
              const response = await axios.get(`${API_URL}/api/v1/payments/`, header);
              let sortedPlans = response.data.sort(
                (a, b) => parseFloat(a.price) - parseFloat(b.price)
              );
              setPlans(sortedPlans);
              setLoading(false);
            }
          };

          const interval = setInterval(verifyPayment, 10000);

          return () => clearInterval(interval);
        } else {
          try {
            let header = getHeader()
            
            const pres_subscribed = await axios.post(
              `${API_URL}/api/v1/payments/subscription/`,
              {},
              header
            );
            if (pres_subscribed.data.alias) {
              if (
                pres_subscribed.data.feeds.length == 0 &&
                pres_subscribed.data.has_access === true
              ) {
                setLoading(false);
                navigate("/dashboard/feed/");
                return;
              } else {
                setLoading(false);
                navigate("/");
                return;
              }
            } else if (
              pres_subscribed.data.alias &&
              pres_subscribed.data.feeds.length >= 1
            ) {
              navigate("/");
              return;
            }
          } catch (error) {}
          let header = getToken()
          const response = await axios.get(`${API_URL}/api/v1/payments/`, header);
          let sortedPlans = response.data.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
          setPlans(sortedPlans);
          setLoading(false);
          return;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          removeAccessToken();
          navigate("/account/");
          setLoading(false);
        } else {
          setError("Error fetching the pricing table.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionList();
  }, [navigate, location.search]);

  const subscribe = async (subscription_id) => {
    if (loading) return;
    setLoading(true);
    setClicked(true);
    try {
      const token = getToken();
      let header = getHeader()
      const response = await axios.post(
        `${API_URL}/api/v1/payments/init/`,
        { subscription_id: subscription_id },
        header
      );
      // setData(response.data);
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      } else {
        let header = getHeader()
        const subscription = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        if (subscription.data.feeds.length == 0) {
          setLoading(false);
          navigate("/dashboard/feed/");
          return;
        } else {
          setLoading(false);
          navigate("/");
          return;
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        removeAccessToken();
        navigate("/login/");
        setLoading(false);
      } else {
        setError("Unable to initialize payment.");
      }
    } finally {
      setClicked(false);
      setLoading(false);
    }
    return {};
  };

  if (loading) return <LoadingPost></LoadingPost>;

  if (loadingPayment) {
    return (
      <>
        <div class="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
          <span class="sr-only">Loading...</span>
          <div class="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div class="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div class="h-8 w-8 bg-black rounded-full animate-bounce"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <SiteHeader></SiteHeader>
      <main className="max-w-6xl mx-auto pt-10 px-8">
        <div className="max-w-md mx-auto mb-14 text-center">
          <h1 className="text-4xl font-semibold mb-6 lg:text-5xl">
            <span className="text-indigo-600">Flexible</span> Plans
          </h1>
          <p className="text-xl text-gray-500 font-medium">
            Choose a plan that works best for you.
          </p>
        </div>
        <div className="flex flex-col justify-between items-center lg:flex-row lg:items-start">
          {plans &&
            plans.map((item, index) => (
              <div
                key={index}
                className={`order-${index} ${
                  !item.selected
                    ? "w-full flex-1 mt-8 p-8 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:rounded-r-none"
                    : "w-full flex-1 mt-8 p-8 shadow-xl rounded-3xl bg-gray-900 text-gray-400 sm:w-96 lg:w-full"
                }`}
              >
                <div className="mb-7 pb-7 flex items-center border-b border-gray-300">
                  <img
                    src="https://res.cloudinary.com/williamsondesign/abstract-1.jpg"
                    alt=""
                    className="rounded-3xl w-20 h-20"
                  />
                  <div className="ml-5">
                    <span className="block text-2xl font-semibold">
                      {item.name}
                    </span>
                    <span>
                      <span className="font-medium text-gray-500 text-xl align-top">
                        $ 
                      </span>
                      <span className="text-3xl font-bold">{item.price}</span>
                    </span>
                  </div>
                </div>
                <ul className="mb-7 font-medium text-gray-500">
                  {item.features.map((item) => (
                    <li key={item.alias} className="flex text-lg mb-2">
                      {" "}
                      <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                      <span className="ml-3">{item.inline_feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => subscribe(item.alias)}
                  disabled={clicked}
                  className="flex w-full justify-center items-center bg-indigo-600 py-2 text-center text-white text-xl disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  {clicked ? (
                    <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <>
                      Subscribe
                      <img
                        src="https://res.cloudinary.com/williamsondesign/arrow-right.svg"
                        className="ml-2"
                      />
                    </>
                  )}
                </button>
              </div>
            ))}
        </div>
      </main>
      <MostViews news={topSix} />
      <Footer />
    </>
  );
}
