import { SiteHeader } from "@/routes/site-header";

import { Footer } from "@/routes/footer";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL } from "@/config";
import { LoadingWindow } from "@/routes/loading";
import { EmptyPage } from "./error";
import { Navbar } from "./navbar";
import { getHeader, getToken } from "./utils";

function SecondNews() {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [provider, setProvider] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const token = getToken()


  // Initial data fetch
  useEffect(() => {
    fetchData(page);
  }, [page]);

  const fetchData = async (page) => {
    setLoading(true);

    let header = getHeader()
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/news/book-mark/?page=${page}`,
        header
      );
      if (page == 1) {
        setData(response.data.results);
      } else {
        setData((prevData) => [...prevData, ...response.data.results]);
      }

      setHasMore(response.data.next || false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const offsetHeight = document.documentElement.offsetHeight;

    if (offsetHeight - (scrollTop + windowHeight) <= 300 && !loading) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  if (loading) return <LoadingWindow></LoadingWindow>;
  return (
    <div className="fbt-main-wrapper col-xl-12">
      <div className="w-full py-3">
        <h2 className="text-gray-800 text-2xl font-bold">
          <span className="inline-block h-5 border-l-3 border-red-600 mr-2"></span>
          Bookmarks
          <hr></hr>
        </h2>
      </div>

      <div id="main-wrapper">
        {data.length == 0 && !loading && (
          <EmptyPage txt={`Sorry!!! You do not have any BookMarks.`} />
        )}
        <div className="main-section" id="main_content">
          <div className="blog-posts fbt-index-post-wrap card-columns">
            {data &&
              data.map((item, index) => (
                <div
                  key={item.alias}
                  className={`blog-post card radius-10 fbt-index-post ${
                    index > 15 ? "" : "hentry"
                  }`}
                >
                  <div className="fbt-post-thumbnail">
                    <a href={`/posts/${item.slug}/`}>
                      <img
                        alt=""
                        className="post-thumbnail lazyloaded"
                        src={item.resized_featured_image}
                      />
                    </a>
                  </div>
                  <div className="fbt-post-caption card-body">
                    <h3 className="post-title h4 card-title">
                      <a href={`/posts/${item.slug}/`}>{item.title}</a>
                    </h3>
                    <div className="post-meta">
                      <span className="post-author">
                        <a href={`/provider/${item.feeder.slug}/`}>
                          {item.feeder.display_name || item.feeder.name}
                        </a>
                      </span>
                      <span className="post-date published">
                        {item.pub_date}
                      </span>
                    </div>
                    <p className="post-excerpt card-text">{item.excerpt}</p>
                  </div>
                </div>
              ))}
          </div>
          {loading && page > 1 && <LoadingWindow></LoadingWindow>}
        </div>
      </div>
    </div>
  );
}

export function BookMarkPost() {
  const token = getToken();
  return (
    <>
      {token ? (
        <Navbar firehouse={false} setFilterConfig={null} />
      ) : (
        <SiteHeader />
      )}
      <div id="page-wrapper" className="full-page feed-view">
        <div className="outer-wrapper clearfix" id="outer-wrapper">
          <div className="xl:container mx-auto px-3 sm:px-4 xl:px-2">
            <div className="row justify-content-center">
              <SecondNews></SecondNews>
            </div>
          </div>
        </div>
      </div>

      <hr></hr>
      <Footer></Footer>
      <a
        href="#"
        className="back-top fixed p-4 rounded bg-gray-100 border border-gray-100 text-gray-500 dark:bg-gray-900 dark:border-gray-800 right-4 bottom-4 block"
        aria-label="Scroll To Top"
      >
        <svg
          width="1rem"
          height="1rem"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 01.5.5v9a.5.5 0 01-1 0V4a.5.5 0 01.5-.5z"
            clipRule="evenodd"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.646 2.646a.5.5 0 01.708 0l3 3a.5.5 0 01-.708.708L8 3.707 5.354 6.354a.5.5 0 11-.708-.708l3-3z"
            clipRule="evenodd"
          ></path>
        </svg>
      </a>
    </>
  );
}
