import React, { useEffect, useState } from "react";
import {
  Menu,
  Search,
  Filter,
  SlidersHorizontal,
  Activity,
  Banknote,
  Newspaper as Feed,
  Bookmark,
  Settings,
  LogOut,
  Rss as Subscription,
  Home,
  Icon,
} from "lucide-react"; // Import icons
import { FilterComponent, StateAcordion } from "./filter";
import { SearchMenu } from "@/routes/search";
import { set } from "date-fns";
import axios from "axios";
import { API_URL } from "@/config";
import { Icons } from "@/components/ui/icon";
import { useNavigate } from "react-router-dom";
import {
  FeedsAccordion,
  SubjectsAccordion,
  PredefinedDatesAccordion,
} from "./filter";
import { getHeader, getToken, removeAccessToken } from "./utils";

const us_states = [
  (1, "Alabama"),
  (2, "Alaska"),
  (3, "Arizona"),
  (4, "Arkansas"),
  (5, "California"),
  (6, "Colorado"),
  (7, "Connecticut"),
  (8, "Delaware"),
  (9, "Florida"),
  (10, "Georgia"),
  (11, "Hawaii"),
  (12, "Idaho"),
  (13, "Illinois"),
  (14, "Indiana"),
  (15, "Iowa"),
  (16, "Kansas"),
  (17, "Kentucky"),
  (18, "Louisiana"),
  (19, "Maine"),
  (20, "Maryland"),
  (21, "Massachusetts"),
  (22, "Michigan"),
  (23, "Minnesota"),
  (24, "Mississippi"),
  (25, "Missouri"),
  (26, "Montana"),
  (27, "Nebraska"),
  (28, "Nevada"),
  (29, "New Hampshire"),
  (30, "New Jersey"),
  (31, "New Mexico"),
  (32, "New York"),
  (33, "North Carolina"),
  (34, "North Dakota"),
  (35, "Ohio"),
  (36, "Oklahoma"),
  (37, "Oregon"),
  (38, "Pennsylvania"),
  (39, "Rhode Island"),
  (40, "South Carolina"),
  (41, "South Dakota"),
  (42, "Tennessee"),
  (43, "Texas"),
  (44, "Utah"),
  (45, "Vermont"),
  (46, "Virginia"),
  (47, "Washington"),
  (48, "West Virginia"),
  (49, "Wisconsin"),
  (50, "Wyoming"),
  (51, "National"),
];

export const Navbar = ({
  setFilterConfig = null,
  firehouse = false,
  post = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFeeds, setSelectedFeeds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [subscribedFeeds, setSubscribedFeeds] = useState([]);
  const [purchased, setPurchased] = useState(false);
  const [topStories, setTopStories] = useState(false);
  const [allFeeds, setAllFeeds] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchKeyword, setSearchKeyWord] = useState(null);
  const token = getToken();
  const navigate = useNavigate();

  const addStates = (state) => {
    let canAdd = true;
    for (let i = 0; i < selectedStates.length; i++) {
      if (state[0] === selectedStates[i][0]) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedStates([...selectedStates, state]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeStates = (state) => {
    setSelectedStates(selectedStates.filter((item) => item[0] !== state[0]));
  };

  function storeData(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  function getData(key) {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
  useEffect(() => {
    const fetchAllFeeds = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setAllFeeds(response.data);
        setSubscribedFeeds(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchSubscription = async () => {
      try {
        let header = getHeader();
        const subscriptionResponse = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        setSubscribedFeeds(subscriptionResponse.data.feeds);
        storeData("feeds", subscriptionResponse.data.feeds);
      } catch (error) {
        console.error("Error during subscription request:", error);
      } finally {
      }
    };
    const fetchTags = async () => {
      let header = getHeader();
      try {
        const tags = await axios.get(
          `${API_URL}/api/v1/news/tags/`,
          {},
          header
        );
        setFilteredCategories(tags.data);
        storeData("categories", tags.data);
      } catch (error) {
        console.error("Error during tags request:", error);
      } finally {
      }
    };
    let ffeeds = getData("feeds");
    let all_feeds = getData("all_feeds");
    let categories = getData("categories");

    if (!firehouse) {
      if (ffeeds) {
        setSubscribedFeeds(ffeeds);
      } else {
        fetchSubscription();
      }
    } else if (firehouse) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    }

    if (categories == null || categories == undefined) {
      fetchTags();
    } else {
      setFilteredCategories(categories);
    }
    clearFilters();
  }, [firehouse]);

  const predefinedRanges = [
    {
      label: "Any",
      start: "",
      end: "",
    },
    {
      label: "Today",
      start: () => new Date().toISOString().split("T")[0],
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "2 Days Ago",
      start: () => getDateDaysAgo(2),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "1 Week Ago",
      start: () => getDateDaysAgo(7),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Custom date",
    },
  ];

  const addCategory = (category) => {
    let canAdd = true;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (category.alias === selectedCategories[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedCategories([...selectedCategories, category]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };

  const addFeeds = (feed) => {
    let canAdd = true;
    for (let i = 0; i < selectedFeeds.length; i++) {
      if (feed.alias === selectedFeeds[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedFeeds([...selectedFeeds, feed]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeFeeds = (feed) => {
    setSelectedFeeds(selectedFeeds.filter((item) => item.alias !== feed.alias));
  };

  const handlePredefinedRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (start == undefined && end == undefined) {
      setSelectedPredefinedRange("Custom date");
    } else if (start == "" && end == "") {
      setSelectedPredefinedRange("Any");
    } else {
      setSelectedPredefinedRange(
        predefinedRanges.find(
          (range) => range.start === start && range.end === end
        ).label
      );
    }
  };

  const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsFilterVisible(false);
  };

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    setIsOpen(false);
  };

  const applyFilters = () => {
    setIsFilterVisible(!isFilterVisible);
    setIsOpen(false);
    if (setFilterConfig) {
      setFilterConfig({
        selectedFeeds: selectedFeeds,
        selectedCategories: selectedCategories,
        purchased: purchased,
        date: {
          startDate,
          endDate,
        },
        topStories: topStories,
        selectedStates: selectedStates,
        searchKeyword: searchKeyword,
      });
    }
  };

  const clearFilters = () => {
    if (setFilterConfig) {
      setFilterConfig({});
    }
    setSearchKeyWord(null);
    setSelectedStates([]);
    setSelectedCategories([]);
    setSelectedFeeds([]);
    setStartDate(null);
    setEndDate(null);
    setTopStories(false);
    setPurchased(false);
    setSelectedPredefinedRange("Any");
  };
  const handleLogOut = () => {
    removeAccessToken();
    window.location.href = "/";
  };

  return (
    <header className="sticky top-0 z-50 bg-white shadow-lg">
      <a
        href="/"
        className="flex items-center justify-center space-y-2 sm:flex md:hidden"
      >
        <span className="font-bold">
          <img src="https://dev.scrollne.ws/logo_nemesis.png" />
        </span>
      </a>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {
            <>
              {setFilterConfig && (
                <>
                  <div className="block items-center flex pl-2">
                    <button
                      onClick={() => navigate("/")}
                      className="text-gray-800 hover:text-blue-600 focus:outline-none"
                    >
                      <Home className="w-5 h-5 mr-3" />
                    </button>
                  </div>
                </>
              )}
              {setFilterConfig && (
                <>
                  <div className="block lg:hidden items-center flex">
                    <button
                      onClick={toggleFilter}
                      className="text-gray-800 hover:text-blue-600 focus:outline-none"
                    >
                      <SlidersHorizontal className="w-6 h-6" />
                    </button>
                  </div>
                </>
              )}
            </>
          }

          {!setFilterConfig && (
            <>
              <div className="hidden md:block">
                <a href="/">
                  <img
                    src="https://dev.scrollne.ws/logo_nemesis.png" // Replace with the actual path to your logo
                    alt="Logo"
                    className="h-8 w-auto"
                  />
                </a>
              </div>
            </>
          )}

          <div className="flex-grow md:flex md:justify-center flex justify-center px-2">
            <SearchMenu />
          </div>

          {/* Menu Icon for Mobile - Right */}
          <div className="block md:hidden items-center flex">
            <button
              onClick={toggleMenu}
              className="text-gray-800 hover:text-blue-600 focus:outline-none"
            >
              <Menu className="w-6 h-6" />
            </button>
          </div>

          {/* Navbar Links for Desktop */}
          <nav className="hidden md:flex space-x-6 items-center">
            <a
              href="/dashboard/profile/"
              className="text-gray-800 hover:text-blue-600 transition-colors"
            >
              Activity
            </a>
            <a
              href="/dashboard/feed/"
              className="text-gray-800 hover:text-blue-600 transition-colors"
            >
              Feed
            </a>
            <a
              href="/dashboard/transaction/history/"
              className="text-gray-800 hover:text-blue-600 transition-colors"
            >
              Bank
            </a>
            <a
              href="/dashboard/subscription/"
              className="text-gray-800 hover:text-blue-600 transition-colors"
            >
              Subscription
            </a>
            <a
              href="/book-mark/"
              className="text-gray-800 hover:text-blue-600 transition-colors"
            >
              Bookmarks
            </a>
            <a
              href="/dashboard/settings/"
              className="text-gray-800 hover:text-blue-600 transition-colors"
            >
              Settings
            </a>
            <a
              href="#"
              onClick={() => {
                handleLogOut();
              }}
              className="text-gray-800 hover:text-red-600 transition-colors"
            >
              Logout
            </a>
          </nav>
        </div>
      </div>
      {isFilterVisible && (
        <div className="fixed left-0 w-2/3 md:w-[270px] bg-gray-100 border-t border-gray-300 z-50">
          <div className="bg-white p-4 h-[calc(100vh-4rem)] overflow-y-auto">
            <h2 className="text-xl font-bold mb-4">Filter</h2>

            <div className="space-y-1">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value="false"
                  onChange={() => setPurchased(!purchased)}
                  checked={purchased}
                  className="mr-2"
                />
                Purchased
              </label>

              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value="false"
                  onChange={() => setTopStories(!topStories)}
                  checked={topStories}
                  className="mr-2"
                />
                Top Stories
              </label>

              <div className="space-y-1 relative">
                <input
                  type="text"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyWord(e.target.value)}
                  placeholder="Search keyword..."
                  className="w-full p-2 border border-gray-300 rounded-md mb-2"
                />
                {searchKeyword && (
                  <button
                    className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                    onClick={() => setSearchKeyWord("")}
                  >
                    &#x2715;
                  </button>
                )}
              </div>
              <StateAcordion
                selectedStates={selectedStates}
                addStates={addStates}
                removeState={removeStates}
              />
              <FeedsAccordion
                selectedFeeds={selectedFeeds}
                subscribedFeeds={subscribedFeeds}
                addFeeds={addFeeds}
                removeFeeds={removeFeeds}
              />

              <SubjectsAccordion
                selectedCategories={selectedCategories}
                filteredCategories={filteredCategories}
                addCategory={addCategory}
                removeCategory={removeCategory}
              />

              <PredefinedDatesAccordion
                selectedDateRange={selectedPredefinedRange}
                predefinedDateRanges={predefinedRanges}
                setDateRange={handlePredefinedRange}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
            </div>

            {/* Apply Button (Sticky at the bottom) */}
            <div className="sticky bottom-0 bg-white py-4">
              <div className="flex justify-between items-center">
                {/* Clear Filters Button */}
                <button
                  onClick={clearFilters}
                  className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {/* Apply Filters Button */}
                <button
                  onClick={applyFilters}
                  className="flex items-center px-4 py-2 bg-info text-white rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-100 p-4 border-t border-gray-300">
          <nav className="flex flex-col space-y-4">
            <a
              href="/"
              className="flex items-center py-1 px-2 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Home className="w-5 h-5 mr-3" />
              Home
            </a>
            <a
              href="/dashboard/profile/"
              className="flex items-center py-1 px-2 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Activity className="w-5 h-5 mr-3" />
              Activity
            </a>
            <a
              href="/dashboard/feed/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors active:bg-blue-200 active:text-blue-700"
            >
              <Feed className="w-5 h-5 mr-3" />
              Feed
            </a>
            <a
              href="/dashboard/transaction/history/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Banknote className="w-5 h-5 mr-3" />
              Bank
            </a>
            <a
              href="/dashboard/subscription/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Subscription className="w-5 h-5 mr-3" />
              Subscription
            </a>
            <a
              href="/book-mark/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Bookmark className="w-5 h-5 mr-3" />
              Bookmarks
            </a>
            <a
              href="/dashboard/settings/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Settings className="w-5 h-5 mr-3" />
              Settings
            </a>
            <a
              href="#logout"
              onClick={() => {
                handleLogOut();
              }}
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-red-100 hover:text-red-600 transition-colors"
            >
              <LogOut className="w-5 h-5 mr-3" />
              Logout
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};
