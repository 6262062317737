import { Icons } from "@/components/ui/icon";
import { API_URL } from "@/config";
import { selectRowsFn } from "@tanstack/react-table";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getHeader, getToken } from "./utils";

const us_states = [
  [1, "Alabama"],
  [2, "Alaska"],
  [3, "Arizona"],
  [4, "Arkansas"],
  [5, "California"],
  [6, "Colorado"],
  [7, "Connecticut"],
  [8, "Delaware"],
  [9, "Florida"],
  [10, "Georgia"],
  [11, "Hawaii"],
  [12, "Idaho"],
  [13, "Illinois"],
  [14, "Indiana"],
  [15, "Iowa"],
  [16, "Kansas"],
  [17, "Kentucky"],
  [18, "Louisiana"],
  [19, "Maine"],
  [20, "Maryland"],
  [21, "Massachusetts"],
  [22, "Michigan"],
  [23, "Minnesota"],
  [24, "Mississippi"],
  [25, "Missouri"],
  [26, "Montana"],
  [27, "Nebraska"],
  [28, "Nevada"],
  [29, "New Hampshire"],
  [30, "New Jersey"],
  [31, "New Mexico"],
  [32, "New York"],
  [33, "North Carolina"],
  [34, "North Dakota"],
  [35, "Ohio"],
  [36, "Oklahoma"],
  [37, "Oregon"],
  [38, "Pennsylvania"],
  [39, "Rhode Island"],
  [40, "South Carolina"],
  [41, "South Dakota"],
  [42, "Tennessee"],
  [43, "Texas"],
  [44, "Utah"],
  [45, "Vermont"],
  [46, "Virginia"],
  [47, "Washington"],
  [48, "West Virginia"],
  [49, "Wisconsin"],
  [50, "Wyoming"],
  [51, "National"],
];

export function StateAcordion({
  selectedStates,
  addStates,
  removeState,
  filterControll = null,
  setFilterControll = null,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestedState, setSuggestedState] = useState([]);

  useEffect(() => {
    if (isOpen === true && setFilterControll) {
      setFilterControll("states");
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(filterControll == "states");
  }, [filterControll]);

  // Update the suggested feeds based on the search term
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value == "" || value == undefined || value == null) {
      setSuggestedState([]);
      return;
    }

    const f_state = us_states.filter((state) => {
      console.log(us_states);
      return state[1].toLowerCase().includes(searchTerm.toLowerCase());
    });

    const limitedSuggestions = f_state.slice(0, 5);
    setSuggestedState(limitedSuggestions);
  };

  const handleSelectFeed = (state) => {
    addStates(state);
    setSearchTerm("");
    setSuggestedState([]);
  };

  return (
    <div className="w-full">
      {/* Accordion Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full p-2 font-medium text-gray-700 border border-gray-300 rounded-md focus:outline-none"
      >
        <span>States</span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Accordion Content */}
      {isOpen && (
        <div className="border border-gray-300 rounded-b-lg p-1 space-y-4">
          {/* Selected Feeds */}
          {selectedStates && selectedStates.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Selected State</h3>
              <div className="flex flex-wrap gap-2">
                {selectedStates.map((state) => (
                  <span
                    key={state[0]}
                    className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                  >
                    {state[1]}
                    <button
                      className="ml-2 text-blue-600 hover:text-blue-800"
                      onClick={() => removeState(state)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Autocomplete Input */}
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search feeds..."
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />

            {searchTerm && (
              <button
                className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                onClick={() => handleInputChange({ target: { value: "" } })} // Clear the input
              >
                &#x2715; {/* This is a simple "X" icon */}
              </button>
            )}

            {/* Suggestions Dropdown */}
            {suggestedState.length > 0 && (
              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-40 overflow-y-auto">
                {suggestedState
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map((state) => (
                    <div
                      key={state[0]}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                      onClick={() => handleSelectFeed(state)}
                    >
                      {state[1]}
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div>
            <div className="space-y-2 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 rounded border border-gray-300 p-2">
              {us_states && us_states.length > 0 ? (
                us_states.map((state) => (
                  <label
                    key={state[0]}
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value={state[0]}
                      onChange={() => addStates(state)}
                      checked={selectedStates.some(
                        (selectedStates) => selectedStates[0] === state[0]
                      )}
                      className="mr-2"
                    />
                    {state[1]}
                  </label>
                ))
              ) : (
                <div className="text-center">
                  <Icons.spin className="text-center" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function FeedsAccordion({
  selectedFeeds,
  subscribedFeeds,
  addFeeds,
  removeFeeds,
  filterControll = null,
  setFilterControll = null,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestedFeeds, setSuggestedFeeds] = useState([]);

  useEffect(() => {
    if (isOpen === true && setFilterControll) {
      setFilterControll("feeds");
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(filterControll == "feeds");
  }, [filterControll]);

  const filteredFeeds = subscribedFeeds.filter((feed) =>
    feed.display_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Update the suggested feeds based on the search term
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value == "" || value == undefined || value == null) {
      setSuggestedFeeds([]);
      return;
    }

    // Filter subscribed feeds based on the search term
    const filteredFeeds = subscribedFeeds.filter((feed) =>
      feed.display_name.toLowerCase().includes(value.toLowerCase())
    );
    const limitedSuggestions = filteredFeeds.slice(0, 5);
    setSuggestedFeeds(limitedSuggestions);
  };

  // Handle selecting a suggested feed
  const handleSelectFeed = (feed) => {
    addFeeds(feed);
    setSearchTerm(""); // Clear the input field
    setSuggestedFeeds([]); // Clear suggestions
  };

  return (
    <div className="w-full">
      {/* Accordion Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full p-2 font-medium text-gray-700 border border-gray-300 rounded-md focus:outline-none"
      >
        <span>Feeds</span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Accordion Content */}
      {isOpen && (
        <div className="border border-gray-300 rounded-b-lg p-1 space-y-4">
          {/* Selected Feeds */}
          {selectedFeeds && selectedFeeds.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Selected Feeds</h3>
              <div className="flex flex-wrap gap-2">
                {selectedFeeds.map((feed) => (
                  <span
                    key={feed.alias}
                    className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                  >
                    {feed.display_name || feed.name}
                    <button
                      className="ml-2 text-blue-600 hover:text-blue-800"
                      onClick={() => removeFeeds(feed)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Autocomplete Input */}
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search feeds..."
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />

            {searchTerm && (
              <button
                className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                onClick={() => handleInputChange({ target: { value: "" } })} // Clear the input
              >
                &#x2715; {/* This is a simple "X" icon */}
              </button>
            )}

            {/* Suggestions Dropdown */}
            {suggestedFeeds.length > 0 && (
              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-40 overflow-y-auto">
                {suggestedFeeds
                  .sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map((feed) => (
                    <div
                      key={feed.alias}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                      onClick={() => handleSelectFeed(feed)}
                    >
                      {feed.display_name}
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div>
            <div className="space-y-2 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 rounded border border-gray-300 p-2">
              {subscribedFeeds && subscribedFeeds.length > 0 ? (
                subscribedFeeds
                  .slice(0, 10)
                  .sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map((feed) => (
                    <label
                      key={feed.alias}
                      className="flex items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        value={feed.alias}
                        onChange={() => addFeeds(feed)}
                        checked={selectedFeeds.some(
                          (selectedFeed) => selectedFeed.alias === feed.alias
                        )}
                        className="mr-2"
                      />
                      {feed.display_name}
                    </label>
                  ))
              ) : (
                <div className="text-center">
                  <Icons.spin className="text-center" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function SubjectsAccordion({
  selectedCategories,
  filteredCategories,
  addCategory,
  removeCategory,
  filterControll = null,
  setFilterControll = null,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestedCategories, setSuggestedCategories] = useState([]);

  useEffect(() => {
    if (isOpen === true && setFilterControll) {
      setFilterControll("tags");
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(filterControll == "tags");
  }, [filterControll]);

  // Update the suggested feeds based on the search term
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value == "" || value == undefined || value == null) {
      setSuggestedCategories([]);
      return;
    }

    // Filter subscribed feeds based on the search term
    const filteredFeeds = filteredCategories.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    const limitedSuggestions = filteredFeeds.slice(0, 5);
    setSuggestedCategories(limitedSuggestions);
  };

  // Handle selecting a suggested feed
  const handleSelectFeed = (category) => {
    addCategory(category);
    setSearchTerm("");
    setSuggestedCategories([]);
  };

  return (
    <div className="w-full">
      {/* Accordion Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full p-2 font-medium text-gray-700 border border-gray-300 rounded-md focus:outline-none"
      >
        <span>Subjects</span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Accordion Content */}
      {isOpen && (
        <div className="border border-gray-300 rounded-b-lg p-1 space-y-4">
          {/* Selected Subjects */}
          {selectedCategories && selectedCategories.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Selected Subjects</h3>
              <div className="flex flex-wrap gap-2">
                {selectedCategories
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((category) => (
                    <span
                      key={category.alias}
                      className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                    >
                      {category.title.toUpperCase()}
                      <button
                        className="ml-2 text-blue-600 hover:text-blue-800"
                        onClick={() => removeCategory(category)}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
              </div>
            </div>
          )}

          {/* Autocomplete Input */}
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search subject..."
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />

            {searchTerm && (
              <button
                className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                onClick={() => handleInputChange({ target: { value: "" } })} // Clear the input
              >
                &#x2715; {/* This is a simple "X" icon */}
              </button>
            )}
            {/* Suggestions Dropdown */}
            {suggestedCategories.length > 0 && (
              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-40 overflow-y-auto">
                {suggestedCategories
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((feed) => (
                    <div
                      key={feed.alias}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                      onClick={() => handleSelectFeed(feed)}
                    >
                      {feed.title.toUpperCase()}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Filtered Categories List */}
          <div>
            <div className="space-y-2 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 rounded border border-gray-300 p-2">
              {filteredCategories && filteredCategories.length > 0 ? (
                filteredCategories
                  .slice(0, 10)
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map(
                    (
                      category // Limit to the first 10 categories
                    ) => (
                      <label
                        key={category.alias}
                        className="flex items-center cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={category.alias}
                          onChange={() => addCategory(category)}
                          checked={selectedCategories.some(
                            (selectedCategory) =>
                              selectedCategory.alias === category.alias
                          )}
                          className="mr-2"
                        />
                        {category.title.toUpperCase()}
                      </label>
                    )
                  )
              ) : (
                <div className="text-center">
                  {/* Placeholder for loading icon or message */}
                  <Icons.spin className="text-center" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export const PredefinedDatesAccordion = ({
  selectedDateRange,
  predefinedDateRanges,
  setDateRange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  filterControll = null,
  setFilterControll = null,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [label, setLabel] = useState("");

  useEffect(() => {
    setLabel(selectedDateRange);
  }, [selectedDateRange]);

  const toggleAccordion = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen === true && setFilterControll) {
      setFilterControll("date");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!filterControll) {
      return;
    }
    setIsOpen(filterControll == "date");
  }, [filterControll]);

  return (
    <div className="accordion">
      {/* Accordion Header */}
      <button
        type="button"
        onClick={toggleAccordion}
        className="flex justify-between items-center w-full p-2 font-medium text-gray-700 border border-gray-300 rounded-md focus:outline-none"
      >
        <span>Predefined Date Ranges</span>
        <svg
          className={`w-4 h-4 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Accordion Body */}
      {isOpen && (
        <div className="mb-6">
          <div className="space-y-2 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 rounded border border-gray-300 p-2">
            {predefinedDateRanges.map((range) => (
              <label
                key={range.label}
                className="flex items-center cursor-pointer"
              >
                <input
                  type="radio"
                  name="predefinedDates"
                  value={range.label}
                  checked={selectedDateRange === range.label}
                  onChange={() => setDateRange(range.start, range.end)}
                  className="mr-2"
                />
                {range.label}
              </label>
            ))}
          </div>

          {selectedDateRange == "Custom date" && (
            <>
              <div className="py-2">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Start Date
                  </label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const FilterComponent = ({
  isFilterVisible,
  setIsFilterVisible,
  firehouse = null,
  setFilterConfig = null,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [priceRange, setPriceRange] = useState([10, 100]);
  const [purchased, setPurchased] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFeeds, setSelectedFeeds] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [subscribedFeeds, setSubscribedFeeds] = useState([]);
  const [topStories, setTopStories] = useState(false);
  const [allFeeds, setAllFeeds] = useState(false);
  const [filterControll, setFilterControll] = useState("date");
  const token = getToken();
  function storeData(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  function getData(key) {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
  function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // Create an object containing the value and the expiration time
    const item = {
      value: value, // The JSON object you're storing
      expiry: now.getTime() + ttl, // The expiration time in milliseconds
    };

    // Convert the object to a string before saving in localStorage
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    // Parse the stored string back into an object
    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the current time with the expiry time
    if (now.getTime() > item.expiry) {
      // If the item has expired, remove it from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    // Return the stored JSON object
    return item.value;
  }

  useEffect(() => {
    const fetchAllFeeds = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setAllFeeds(response.data);
        storeData("all_feeds", response.data);
        setSubscribedFeeds(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchSubscription = async () => {
      try {
        let header = getHeader()
        const subscriptionResponse = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        setSubscribedFeeds(subscriptionResponse.data.feeds);
        storeData("feeds", subscriptionResponse.data.feeds);
      } catch (error) {
        console.error("Error during subscription request:", error);
      } finally {
      }
    };
    const fetchTags = async () => {
      try {
        const tags = await axios.get(`${API_URL}/api/v1/news/tags/`);
        setFilteredCategories(tags.data);
        setWithExpiry("categories", tags.data, 7 * 24 * 60 * 60 * 1000);
      } catch (error) {
        console.error("Error during tags request:", error);
      } finally {
      }
    };
    let ffeeds = getData("feeds");
    let all_feeds = getData("all_feeds");
    let categories = getWithExpiry("categories");

    if (!token) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    } else if (!firehouse) {
      if (ffeeds) {
        setSubscribedFeeds(ffeeds);
      } else {
        fetchSubscription();
      }
    } else if (firehouse) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    }

    if (categories == null || categories == undefined) {
      fetchTags();
    } else {
      setFilteredCategories(categories);
    }
    clearFilters();
  }, [firehouse]);

  const predefinedRanges = [
    {
      label: "Any",
      start: "",
      end: "",
    },
    {
      label: "Today",
      start: () => new Date().toISOString().split("T")[0],
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "2 Days Ago",
      start: () => getDateDaysAgo(2),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "1 Week Ago",
      start: () => getDateDaysAgo(7),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Custom date",
    },
  ];

  const addCategory = (category) => {
    let canAdd = true;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (category.alias === selectedCategories[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedCategories([...selectedCategories, category]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };
  const addStates = (state) => {
    let canAdd = true;
    for (let i = 0; i < selectedStates.length; i++) {
      if (state[0] === selectedStates[i][0]) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedStates([...selectedStates, state]);
    }
    setSearchTerm(""); // Clear the search term
  };
  

  const addFeeds = (feed) => {
    let canAdd = true;
    for (let i = 0; i < selectedFeeds.length; i++) {
      if (feed.alias === selectedFeeds[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedFeeds([...selectedFeeds, feed]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeFeeds = (feed) => {
    setSelectedFeeds(selectedFeeds.filter((item) => item.alias !== feed.alias));
  };

  const handlePredefinedRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (start == undefined && end == undefined) {
      setSelectedPredefinedRange("Custom date");
    } else if (start == "" && end == "") {
      setSelectedPredefinedRange("Any");
    } else {
      setSelectedPredefinedRange(
        predefinedRanges.find(
          (range) => range.start === start && range.end === end
        ).label
      );
    }
  };

  const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };

  const toggleCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const applyFilters = () => {
    setFilterConfig({
      selectedFeeds: selectedFeeds,
      selectedCategories: selectedCategories,
      purchased: purchased,
      date: {
        startDate,
        endDate,
      },
      topStories: topStories,
    });
  };
  const removeStates = (state) => {
    setSelectedStates(selectedStates.filter((item) => item[0] !== state[0]));
  };

  const clearFilters = () => {
    setFilterConfig({});
    setSelectedCategories([]);
    setSelectedFeeds([]);
    setStartDate(null);
    setEndDate(null);
    setTopStories(false);
    setPurchased(false);
    setSelectedPredefinedRange("Any");
  };

  return (
    <div className="fixed h-full hidden md:block ">
      <div
        className={`bg-white p-4 h-full shadow-lg z-10 transform ${
          isFilterVisible ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:relative md:translate-x-0 md:w-64`}
      >
        <h2 className="text-xl font-bold mb-4">Filter</h2>

        <div className="space-y-1">
          {token && (
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                value="false"
                onChange={() => setPurchased(!purchased)}
                checked={purchased}
                className="mr-2"
              />
              Purchased
            </label>
          )}
          
          <StateAcordion
            selectedStates={selectedStates}
            addStates={addStates}
            removeState={removeStates}
            filterControll={filterControll}
            setFilterControll={setFilterControll}
          />
          <FeedsAccordion
            selectedFeeds={selectedFeeds}
            subscribedFeeds={subscribedFeeds}
            addFeeds={addFeeds}
            removeFeeds={removeFeeds}
            filterControll={filterControll}
            setFilterControll={setFilterControll}
          />

          <SubjectsAccordion
            selectedCategories={selectedCategories}
            filteredCategories={filteredCategories}
            addCategory={addCategory}
            removeCategory={removeCategory}
            filterControll={filterControll}
            setFilterControll={setFilterControll}
          />

          <PredefinedDatesAccordion
            selectedDateRange={selectedPredefinedRange}
            predefinedDateRanges={predefinedRanges}
            setDateRange={handlePredefinedRange}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            filterControll={filterControll}
            setFilterControll={setFilterControll}
          />
        </div>

        <div className="bg-white py-2 px-4">
          <div className="flex justify-between items-center">
            {/* Clear Filters Button */}
            <button
              onClick={clearFilters}
              className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Apply Filters Button */}
            <button
              onClick={applyFilters}
              className="flex items-center px-4 py-2 bg-info text-white rounded-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Overlay for mobile */}
      {isFilterVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-5 md:hidden"
          onClick={() => setIsFilterVisible(false)}
        />
      )}
    </div>
  );
};
