import { FeederChart } from "@/routes/dashboard/feed-chart";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Newspaper, EyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "@/config";
import { useNavigate } from "react-router-dom";

import { Badge } from "@/components/ui/badge";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { getHeader, getToken } from "../utils";

const BookMarkList = () => {
  const [bookMarks, setBookMarks] = useState([]);
  const [amount, setAmount] = useState(0);
  const [prevous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);
  const [page, setPage] = useState(1);
  const token = getToken();

  const fetchBookMark = async () => {
    try {
      let header = getHeader()
      const resp = await axios.get(
        `${API_URL}/api/v1/news/book-mark/?page=${page}`,
        header
      );
      setAmount(resp.data.count)
      setNext(resp.data.next);
      setPrevious(resp.data.previous);
      setBookMarks(resp.data.results);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchBookMark();
  }, []);

  useEffect(() => {
    fetchBookMark();
  }, [page]);

  const calculateDaysRemaining = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    const diffTime = end - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

    // Generate a message based on the number of days
    if (diffDays < 1) {
      return "Expires Today or Midnight Days";
    } else if (diffDays === 1) {
      return "Expires in 1 Day";
    } else {
      return `Expires in ${diffDays} Days`;
    }
  };

  // Function to format a date into 'Month Day, Year' format
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div>
      {/* <h2 className="text-lg font-bold mb-4">Bank Statement</h2> */}

      <div className="mt-4">
        <p className="text-sm font-semibold">
          Current Total: <span>{amount || 0}</span>
        </p>
        <p className="text-sm text-gray-500">
          Articles saved to read at a later time. Bookmarks expire after <b>60</b> {` `}
          days.
        </p>
      </div>

      <ul className="list-disc list-inside space-y-6 mt-6">
        {bookMarks &&
          bookMarks.map((item, index) => (
            <li key={index} className="">
              <a
                href={`/posts/${item.slug}/`}
                className="text-lg font-semibold underline hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
              <div className="text-sm text-gray-700 mt-1">
                <a
                  href={`/provider/${item.feeder.slug}/`}
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.feeder.display_name || item.feeder.name}
                </a>
              </div>
              <div className="text-sm text-gray-500 mt-2">
                <span>Added at: {formatDate(item.bookmark.created)}</span>
              </div>
              <div className="text-sm font-semibold mt-1">
                <span>{calculateDaysRemaining(item.bookmark.valid_till)}</span>
              </div>
            </li>
          ))}
      </ul>
      <div className="flex justify-between mt-4">
        <button
          disabled={prevous == null}
          onClick={() => {
            setPage(page - 1);
          }}
          className="bg-info px-4 py-2 bg-gray-300 text-gray-800 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={next == null}
          className="bg-info px-4 py-2 bg-gray-300 text-gray-800 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export function BookMarks() {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Book Marks</h3>
        {/* <p className="text-sm text-muted-foreground">
          List of article purchases transactions
        </p> */}
      </div>
      <Separator />

      <BookMarkList />
    </>
  );
}
