import { v4 as uuidv4 } from 'uuid';

const TOKEN_KEY = "token";
const EXPIRATION_KEY = "token_expiration";
const EXPIRATION_DAYS = 6;

export const generateUniqueId = () => {
  return uuidv4(); 
};

// Helper function to calculate expiration date
const calculateExpirationDate = (days) => {
  const now = new Date();
  now.setDate(now.getDate() + days);
  return now.getTime(); // Returns timestamp in milliseconds
};

// Set token with expiration
export const setToken = (value) => {
  try {
    const expirationDate = calculateExpirationDate(EXPIRATION_DAYS);
    localStorage.setItem(TOKEN_KEY, value);
    localStorage.setItem(EXPIRATION_KEY, expirationDate.toString());
  } catch (error) {
    console.error("Error setting token:", error);
  }
};

// Get token and check expiration
export const getToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    const expiration = localStorage.getItem(EXPIRATION_KEY);

    if (!token || !expiration) {
      return null; // No token or expiration date found
    }

    const now = Date.now();
    if (now > parseInt(expiration, 10)) {
      // Token has expired
      removeAccessToken(); // Remove token and expiration
      return null;
    }

    return token; // Token is valid
  } catch (error) {
    console.error("Error getting token:", error);
    return null;
  }
};

// Remove token and expiration date
export const removeAccessToken = () => {
  try {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(EXPIRATION_KEY);
  } catch (error) {
    console.error("Error removing token:", error);
  }
};

// Get headers with token if valid
export const getHeader = () => {
  const token = getToken();
  let header = {};
  if (token) {
    header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return header;
};
