import { useEffect, useState } from "react";
import { getHeader, getToken } from "./utils";
import axios from "axios";
import { API_URL } from "@/config";
import {
  FeedsAccordion,
  PredefinedDatesAccordion,
  StateAcordion,
  SubjectsAccordion,
} from "./filter";

export const TestFilter = ({ setFilterConfig = null, firehouse = null }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [priceRange, setPriceRange] = useState([10, 100]);
  const [purchased, setPurchased] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFeeds, setSelectedFeeds] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [subscribedFeeds, setSubscribedFeeds] = useState([]);
  const [topStories, setTopStories] = useState(false);
  const [allFeeds, setAllFeeds] = useState(false);
  const [filterControll, setFilterControll] = useState("date");
  const token = getToken();
  function storeData(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  function getData(key) {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
  function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // Create an object containing the value and the expiration time
    const item = {
      value: value, // The JSON object you're storing
      expiry: now.getTime() + ttl, // The expiration time in milliseconds
    };

    // Convert the object to a string before saving in localStorage
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    // Parse the stored string back into an object
    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the current time with the expiry time
    if (now.getTime() > item.expiry) {
      // If the item has expired, remove it from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    // Return the stored JSON object
    return item.value;
  }

  useEffect(() => {
    const fetchAllFeeds = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setAllFeeds(response.data);
        storeData("all_feeds", response.data);
        setSubscribedFeeds(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchSubscription = async () => {
      try {
        let header = getHeader();
        const subscriptionResponse = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        setSubscribedFeeds(subscriptionResponse.data.feeds);
        storeData("feeds", subscriptionResponse.data.feeds);
      } catch (error) {
        console.error("Error during subscription request:", error);
      } finally {
      }
    };
    const fetchTags = async () => {
      try {
        const tags = await axios.get(`${API_URL}/api/v1/news/tags/`);
        setFilteredCategories(tags.data);
        setWithExpiry("categories", tags.data, 7 * 24 * 60 * 60 * 1000);
      } catch (error) {
        console.error("Error during tags request:", error);
      } finally {
      }
    };
    let ffeeds = getData("feeds");
    let all_feeds = getData("all_feeds");
    let categories = getWithExpiry("categories");

    if (!token) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    } else if (!firehouse) {
      if (ffeeds) {
        setSubscribedFeeds(ffeeds);
      } else {
        fetchSubscription();
      }
    } else if (firehouse) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    }

    if (categories == null || categories == undefined) {
      fetchTags();
    } else {
      setFilteredCategories(categories);
    }
    clearFilters();
  }, [firehouse]);

  const predefinedRanges = [
    {
      label: "Any",
      start: "",
      end: "",
    },
    {
      label: "Today",
      start: () => new Date().toISOString().split("T")[0],
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "2 Days Ago",
      start: () => getDateDaysAgo(2),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "1 Week Ago",
      start: () => getDateDaysAgo(7),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Custom date",
    },
  ];

  const addCategory = (category) => {
    let canAdd = true;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (category.alias === selectedCategories[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedCategories([...selectedCategories, category]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };
  const addStates = (state) => {
    let canAdd = true;
    for (let i = 0; i < selectedStates.length; i++) {
      if (state[0] === selectedStates[i][0]) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedStates([...selectedStates, state]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const addFeeds = (feed) => {
    let canAdd = true;
    for (let i = 0; i < selectedFeeds.length; i++) {
      if (feed.alias === selectedFeeds[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedFeeds([...selectedFeeds, feed]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeFeeds = (feed) => {
    setSelectedFeeds(selectedFeeds.filter((item) => item.alias !== feed.alias));
  };

  const handlePredefinedRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (start == undefined && end == undefined) {
      setSelectedPredefinedRange("Custom date");
    } else if (start == "" && end == "") {
      setSelectedPredefinedRange("Any");
    } else {
      setSelectedPredefinedRange(
        predefinedRanges.find(
          (range) => range.start === start && range.end === end
        ).label
      );
    }
  };

  const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };

  const toggleCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const applyFilters = () => {
    setFilterConfig({
      selectedFeeds: selectedFeeds,
      selectedCategories: selectedCategories,
      purchased: purchased,
      selectedStates: selectedStates,
      date: {
        startDate,
        endDate,
      },
      topStories: topStories,
    });
  };
  const removeStates = (state) => {
    setSelectedStates(selectedStates.filter((item) => item[0] !== state[0]));
  };

  const clearFilters = () => {
    setFilterConfig({});
    setSelectedCategories([]);
    setSelectedFeeds([]);
    setSelectedStates([]);
    setStartDate(null);
    setEndDate(null);
    setTopStories(false);
    setPurchased(false);
    setSelectedPredefinedRange("Any");
  };
  return (
    <div className="w-full max-w-4xl mx-auto py-6 px-4  ">
      <div className="flex flex-wrap items-center  border justify-between gap-4 bg-white border-gray p-2 rounded-lg">
        <div className="border-l-4 border-blue-500 pl-4">Filter</div>

        <div className="space-y-1 w-full">
          {token && (
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                value="false"
                onChange={() => setPurchased(!purchased)}
                checked={purchased}
                className="mr-2"
              />
              Purchased
            </label>
          )}

          <div className="max-h-60 overflow-y-auto">
            <StateAcordion
              selectedStates={selectedStates}
              addStates={addStates}
              removeState={removeStates}
              filterControll={filterControll}
              setFilterControll={setFilterControll}
            />
          </div>

          <div className="max-h-60 overflow-y-auto">
            <FeedsAccordion
              selectedFeeds={selectedFeeds}
              subscribedFeeds={subscribedFeeds}
              addFeeds={addFeeds}
              removeFeeds={removeFeeds}
              filterControll={filterControll}
              setFilterControll={setFilterControll}
            />
          </div>

          <div className="max-h-60 overflow-y-auto">
            <SubjectsAccordion
              selectedCategories={selectedCategories}
              filteredCategories={filteredCategories}
              addCategory={addCategory}
              removeCategory={removeCategory}
              filterControll={filterControll}
              setFilterControll={setFilterControll}
            />
          </div>

          <PredefinedDatesAccordion
            selectedDateRange={selectedPredefinedRange}
            predefinedDateRanges={predefinedRanges}
            setDateRange={handlePredefinedRange}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            filterControll={filterControll}
            setFilterControll={setFilterControll}
          />
        </div>
        
      </div>
      <div className="bg-white py-2 px-4">
          <div className="flex justify-between items-center">
            {/* Clear Filters Button */}
            <button
              onClick={clearFilters}
              className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Apply Filters Button */}
            <button
              onClick={applyFilters}
              className="flex items-center px-4 py-2 bg-info text-white rounded-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </button>
          </div>
        </div>
    </div>
  );
};
