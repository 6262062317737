// const API_URL = 'https://api.dev.scrollne.ws';
const API_URL = import.meta.env.VITE_API_APP_URL;
const SOCKET_URL = import.meta.env.VITE_API_SOCKET_URL;
const NO_IMAGE_URL = "https://dev.scrollne.ws/no_image.png";
const NO_IMAGES = [
  "https://dev.scrollne.ws/noimage_01.png",
  "https://dev.scrollne.ws/noimage_02.png",
  "https://dev.scrollne.ws/noimage_03.png",
  "https://dev.scrollne.ws/noimage_04.png"
];
const API_KEY = "key";

function getRandomImage() {
    const randomIndex = Math.floor(Math.random() * NO_IMAGES.length);
    return NO_IMAGES[randomIndex];
  }

export { API_URL, SOCKET_URL, NO_IMAGE_URL, API_KEY, NO_IMAGES, getRandomImage };