"use client";

import { MessageSquareDiff } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

import React, { useState, useEffect } from "react";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icon";
import { Button } from "@/components/ui/button";
import { API_URL } from "@/config";
import { LoginPage } from "@/routes/auth/login";
import axios from "axios";

import { KeySquare } from "lucide-react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { useNavigate } from "react-router-dom";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { getRandomQuote } from "./quotes";
import { getToken } from "../utils";

export function AcceptTS() {
  return (
    <div>
      <div className="flex space-x-2 py-1">
        <Checkbox required id="terms" />
        <Label htmlFor="terms">Accept terms and conditions</Label>
      </div>
    </div>
  );
}

export function AlertDestructiveMessage({ msg }) {
  if (!msg) return <></>;
  return (
    <Alert variant="destructive">
      {msg.first_name && (
        <AlertDescription>Name: {msg.first_name}</AlertDescription>
      )}
      {msg.email && <AlertDescription>Email: {msg.email}</AlertDescription>}
      {msg.password && (
        <AlertDescription>Password: {msg.password}</AlertDescription>
      )}
    </Alert>
  );
}

export function SignUp1() {
  return (
    <Card className="mx-auto max-w-sm mg-top-space">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl">Create an account</CardTitle>
        <CardDescription>
          Enter your email below to create your account
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div className="grid grid-cols-2 gap-6">
          <Button variant="outline">
            <MessageSquareDiff className="mr-2 h-4 w-4" />
            Github
          </Button>
          <Button variant="outline">
            <MessageSquareDiff className="mr-2 h-4 w-4" />
            Google
          </Button>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">
              Or continue with
            </span>
          </div>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="name">Name</Label>
          <Input placeholder="Mark Ruffolo" id="name" type="name" />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="email">Email</Label>
          <Input id="email" type="email" placeholder="m@example.com" />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="password">Password</Label>
          <Input placeholder="********" id="password" type="password" />
        </div>
      </CardContent>
      <CardFooter>
        <Button className="w-full">Create account</Button>
      </CardFooter>
    </Card>
  );
}

export function SignUp({ className, ...props }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [first_name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 12;

    if (password.length < minLength) {
      return "Password must be at least 12 characters long.";
    }
    return "";
  };


  useEffect(() => {
    // Check if token is in localStorage
    const token = getToken();
    if (token) {
      // If token exists, navigate to a protected route (e.g., dashboard)
      navigate("/"); // Change this to your desired protected route
    }
  }, [navigate]);

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError("");
    

    if (password !== password1) {
      setPasswordError("Passwords do not match");
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/api/v1/users/`, {
        email,
        password,
        first_name,
      });
      localStorage.setItem("user", response.data.alias);
      const path = localStorage.getItem("ldirect");
      if (!response.data.confirmed) {
        navigate("/email/verify/");
      } else {
        localStorage.removeItem("ldirect")
        navigate(path);
      }
    } catch (error) {
      // Handle error response
      setError(error.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  const handlePasswordChange = (e, fn) => {
    const value = e.target.value;
    fn(value);
    const validationError = validatePassword(value);
    setPasswordError(validationError);
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <AlertDestructiveMessage msg={error} />
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              required
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(e) => setEmail(e.target.value)}
            />
            
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="password1">
              Password
            </Label>
            <Input
              id="password1"
              placeholder="Enter passowrd"
              type="password"
              autoCorrect="off"
              required
              autoComplete="true"
              disabled={isLoading}
              onChange={(e) => handlePasswordChange(e, setPassword)}
            />
            {passwordError && (
              <p className="text-red-600 text-sm">{passwordError}</p>
            )}
          </div>

          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="password">
              Confirm Password
            </Label>
            <Input
              id="password1"
              placeholder="Confirm passowrd"
              type="password"
              autoCorrect="off"
              required
              autoComplete="true"
              disabled={isLoading}
              onChange={(e) => handlePasswordChange(e, setPassword1)}
            />
            {passwordError && (
                <p className="text-red-600 text-sm">{passwordError}</p>
              )}
          </div>

          <Button className="bg-info" disabled={isLoading}>
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Continue
          </Button>
          
        </div>
      </form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or LOGIN HERE</span>
        </div>
      </div>

      <div className="flex flex-col space-y-2 text-center">
        <p className="text-sm text-muted-foreground ">
          <b>Already have an account?</b>
        </p>
        <Button  className="bg-info" onClick={() => navigate('/login/')} variant="outline" type="button" disabled={isLoading}>
          {isLoading ? (
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <KeySquare className="mr-2 h-4 w-4" />
          )}{" "}
          Login
        </Button>
      </div>
    </div>
  );
}

export const metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
};

export function AuthenticationPage() {
  const navigate = useNavigate();
  const quote = getRandomQuote()
  return (
    <>
      <div className="md:hidden">
        <img
          src="https://dev.scrollne.ws/logo_nemesis.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="block dark:hidden p-4"
          onClick={() => navigate("/")}
        />
        <img
          src="https://dev.scrollne.ws/logo_nemesis.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="hidden dark:block"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="container relative h-[800px] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-info" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <a style={{ hover: "none" }} href="/">
              scrollne.ws
            </a>
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;{quote.quote}&rdquo;
              </p>
              <footer className="text-sm">{quote.artist}</footer>
            </blockquote>
          </div>
          
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Create an account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to create your account
              </p>
            </div>
            <SignUp />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
