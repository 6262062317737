import { useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  LogOut,
  BellDot,
  Newspaper,
  User,
  Menu,
  X,
  CalendarCheck2,
  BadgeDollarSign,
  Home,
  BookmarkPlus,
  Settings,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { removeAccessToken } from "../utils";

export function Sidebar({ className }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  const menuItems = [
    { label: "Activity", icon: User, path: "/dashboard/profile/" },
    { label: "Feed", icon: Newspaper, path: "/dashboard/feed/" },
    {
      label: "Subscription",
      icon: CalendarCheck2,
      path: "/dashboard/subscription/",
    },
    { label: "Bookmark", icon: BookmarkPlus, path: "/dashboard/bookmark/" },
    {
      label: "Bank",
      icon: BadgeDollarSign,
      path: "/dashboard/transaction/history/",
    },
    { label: "Settings", icon: Settings, path: "/dashboard/settings/" },
  ];

  const handleLogout = () => {
    removeAccessToken()
    navigate("/account/");
  };

  return (
    <>
      {/* This button is now only visible when the sidebar is closed */}
      <div className="lg:hidden absolute top-2 left-2 ">
        {!isOpen && (
          <Button onClick={toggleSidebar} variant="ghost">
            <Menu className="h-6 w-6" />
          </Button>
        )}
      </div>
      <div className="lg:hidden absolute top-2 right-2">
        {!isOpen && (
          <Button
            onClick={() => {
              navigate("/");
            }}
            variant="ghost"
          >
            <Home className="h-6 w-6" />
          </Button>
        )}
      </div>

      {/* Sidebar container */}
      <div
        className={cn(
          "fixed inset-0 z-40 lg:relative lg:z-auto transform lg:transform-none transition-transform duration-300 lg:transition-none bg-white lg:bg-transparent",
          isOpen ? "translate-x-0" : "-translate-x-full",
          "pb-12 lg:block lg:w-64",
          className
        )}
      >
        {/* Top section of the sidebar, with the close button */}
        <div className="flex items-center justify-between px-4 py-2 lg:hidden">
          <h2 className="text-lg font-semibold tracking-tight">Scrollnews</h2>
          <Button onClick={toggleSidebar} variant="ghost">
            <X className="h-6 w-6" />
          </Button>
        </div>

        <div className="">
          <div className="px-3 py-2">
            <Button
              onClick={() => {
                navigate("/");
              }}
              variant="ghost"
              className="w-full justify-start"
            >
              <Home className="mr-2 h-4 w-4" />
              Home
            </Button>
            {menuItems.map((item) => (
              <Button
                key={item.path}
                onClick={() => {
                  navigate(item.path);
                  setIsOpen(false);
                }}
                variant={
                  location.pathname === item.path ? "secondary" : "ghost"
                }
                className="w-full justify-start"
              >
                <item.icon className="mr-2 h-4 w-4" />
                <span>{item.label}</span>
              </Button>
            ))}

            <Button
              onClick={() => {
                handleLogout();
              }}
              variant="ghost"
              className="w-full justify-start"
            >
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </Button>
          </div>

          <div className="py-2">
            <h2 className="relative px-7 text-lg font-semibold tracking-tight">
              Subscribed
            </h2>
          </div>
        </div>
      </div>

      {/* Overlay for closing the sidebar */}
      {isOpen && (
        <div
          className="fixed inset-0 z-30 bg-black opacity-50 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
}
