import { ArrowDown } from "lucide-react";
import { ArticleCardSkeleton } from "./articles";

export function LoadingPostWindow() {
  return (
    <div className="w-full">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="mb-4">
          <ArticleCardSkeleton />
        </div>
      ))}
    </div>
  );
}

export function LoadingWindow() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-10 mx-auto animate-pulse">
        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 sm:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2">
          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>
          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>
          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>

          <div className="w-full ">
            <div className="w-full h-64 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

            <h1 className="w-56 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
            <p className="w-24 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export function LoadMore({ hasMore }) {
  return (
    <>
      {hasMore && (
        <div className="blog-pager" id="blog-pager">
          <div className="list-inline">
            <a
              className="blog-pager-older-link list-inline-item pointer"
              title="More posts"
              onClick={handleLoadMore}
            >
              <div className="fbt-bp-message text-uppercase font-weight-bold">
                More posts
              </div>
              <ArrowDown className="justify-center inline-flex"></ArrowDown>
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export const LoadingPost = () => {
  return (
    <div className="space-y-6 p-6 animate-pulse w-full max-w-4xl mx-auto">
      {/* Image Section Skeleton */}
      <div className="w-full h-64 bg-gray-300 rounded-lg"></div>

      {/* Title Section Skeleton */}
      <div className="h-8 bg-gray-300 rounded w-2/3 "></div>

      {/* Author, Provider, Date Section Skeleton */}
      <div className="flex space-x-4 mt-4">
        <div className="h-6 bg-gray-300 rounded w-1/4"></div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="h-6 bg-gray-300 rounded w-1/4"></div>
        <span className="h-6 bg-gray-300 rounded w-1"></span>
        <div className="h-6 bg-gray-300 rounded w-1/6"></div>
        <span className="h-6 bg-gray-300 rounded w-1"></span>
        <div className="h-6 bg-gray-300 rounded w-1/6"></div>
      </div>

      {/* Content Section Skeleton */}
      <div className="space-y-4">
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-1/3"></div>

        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-1/4"></div>

        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="h-6 bg-gray-300 rounded w-1/2"></div>
        
      </div>

      {/* Tags Section Skeleton */}
      <div className="flex space-x-3 mt-6">
        {[1, 2, 3, 4].map((_, index) => (
          <div
            key={index}
            className="h-8 bg-gray-300 rounded-full w-20"
          ></div>
        ))}
      </div>
    </div>
  );
};



export function NewLoading() {
  return (
    <div
      id="sticky-banner"
      tabindex="-1"
      className="fixed top-0 start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
    >
      <div className="flex items-center mx-auto">
        <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
          <span className="inline-flex p-1 me-3 bg-gray-200 rounded-full dark:bg-gray-600 w-6 h-6 items-center justify-center flex-shrink-0">
            <svg
              className="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 19"
            >
              <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
            </svg>
            <span className="sr-only">Light bulb</span>
          </span>
          <span>
            New brand identity has been launched for the{" "}
            <a
              href="https://flowbite.com"
              className="inline font-medium text-blue-600 underline dark:text-blue-500 underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline"
            >
              Flowbite Library
            </a>
          </span>
        </p>
      </div>
      <div className="flex items-center">
        <button
          data-dismiss-target="#sticky-banner"
          type="button"
          className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close banner</span>
        </button>
      </div>
    </div>
  );
}
