import { API_URL, getRandomImage, NO_IMAGE_URL } from "@/config";
import { useNavigate } from "react-router-dom";

export const ArticleCard = ({ article }) => {
  const truncatedExcerpt = article.excerpt
    ? article.excerpt.length > 100
      ? article.excerpt.substring(0, 100) + "..."
      : article.excerpt
    : "";

  const navigate = useNavigate();

  function splitAndSortTags(tags) {
    const subjectTags = [];
    const regionTags = [];
    const others = [];

    // Split the tags into respective categories
    tags.forEach((tag) => {
      if (tag.is_subject_tag) {
        subjectTags.push(tag);
      } else if (tag.is_region_tag) {
        regionTags.push(tag);
      } else {
        others.push(tag);
      }
    });

    // Function to sort tags by the length of the title
    const sortByTitleLength = (a, b) => a.title.length - b.title.length;

    // Sort each category
    subjectTags.sort(sortByTitleLength);
    regionTags.sort(sortByTitleLength);
    others.sort(sortByTitleLength);

    return {
      subjectTags,
      regionTags,
      others,
    };
  }
  let article_tags = splitAndSortTags(article.tags);

  return (
    <div
      className="flex custom-border flex-col md:flex-row bg-white p-3 rounded-lg relative border w-full"
      style={{
        transition: "transform 0.2s ease-in-out",
        borderWidth: "1px", // Ensure 1px border
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.02)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      <div
        className="w-full md:w-1/3 overflow-hidden rounded-lg mb-4 md:mb-0 cursor-pointer"
        // onClick={() => navigate(`/posts/${article.slug}/`)}
      >
        <img
          className="h-48 w-full object-cover rounded-lg"
          src={
            article.resized_featured_image
              ? article.resized_featured_image
              : article.alt_img
              ? `${API_URL}/${article.alt_img}`
              : getRandomImage()
          }
          alt={article.title}
        />
      </div>

      {/* Content Section */}
      <div className="w-full md:w-2/3 p-3 flex flex-col justify-between">
        {/* Title */}
        <h3 className="text-xl font-semibold text-gray-800 mb-2 underline-transition">
          <a
            href={`/posts/${article.slug}/`}
            className=" text-gray-800 hover:text-gray-800 no-underline relative inline-block pb-1"
          >
            {article.title}
          </a>
        </h3>

        {/* Excerpt */}
        {article.excerpt && (
          <p className="text-gray-600 mt-2 line-clamp-2">{truncatedExcerpt}</p>
        )}

        {/* Provider Info, Date, and Tags */}
        <div className="mt-4">
          <div className="flex items-center text-sm text-gray-500">
            <span className="font-semibold pointer">
              {article.feeder.display_name || article.feeder.name}
            </span>
            <span className="mx-2">|</span>
            <span>{article.pub_date}</span>
          </div>

          {/* Tags */}
          <div className="flex flex-wrap gap-2 mt-2 max-w-full">
            {article_tags.subjectTags.length > 0 && (
              <div className="flex items-center gap-2">
                <span
                  style={{
                    backgroundColor: "#e68780",
                    color: "#fff",
                  }}
                  className="pointer bg-blue-200 text-blue-800 px-2 py-1 rounded-full text-xs break-words max-w-full truncate"
                >
                  <a
                    href={`/tags/${article_tags.subjectTags[0].slug}/`}
                    className="hover:no-underline hover:text-inherit"
                  >
                    {article_tags.subjectTags[0].title}
                  </a>
                </span>
                {(article_tags.regionTags.length > 0 ||
                  article_tags.others.length > 0) && (
                  <span className="text-gray-400">|</span>
                )}
              </div>
            )}

            {article_tags.regionTags.length > 0 && (
              <div className="flex items-center gap-2">
                <span
                  onClick={() =>
                    navigate(`/tags/${article_tags.regionTags[0].slug}/`)
                  }
                  style={{
                    backgroundColor: "#8fd880",
                    color: "#fff",
                  }}
                  className="pointer text-blue-800 px-2 py-1 rounded-full text-xs break-words max-w-full truncate"
                >
                  <a
                    href={`/tags/${article_tags.regionTags[0].slug}/`}
                    className="hover:no-underline hover:text-inherit"
                  >
                    {article_tags.regionTags[0].title}
                  </a>
                </span>
                {article_tags.others.length > 0 && (
                  <span className="text-gray-400">|</span>
                )}
              </div>
            )}

            {article_tags.others.length > 0 && (
              <div className="flex items-center gap-2">
                {article_tags.others.slice(0, 3).map((tag, index) => (
                  <span
                    // onClick={() => navigate(`/tags/${tag.slug}/`)}
                    key={index}
                    className="pointer bg-blue-200 text-blue-800 px-2 py-1 rounded-full text-xs break-words max-w-full truncate"
                  >
                    <a
                      href={`/tags/${tag.slug}/`}
                      className="hover:no-underline hover:text-inherit"
                    >
                      {tag.title}
                    </a>
                    {/* {tag.title} */}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ArticleCardSkeleton = () => {
  return (
    <div className="flex flex-col md:flex-row bg-gray-100 p-3 shadow-md rounded-lg animate-pulse w-full py-5">
      {/* Image Section Skeleton */}
      <div className="w-full md:w-1/3 overflow-hidden rounded-lg mb-4 md:mb-0 bg-gray-300 h-48"></div>

      {/* Content Section Skeleton */}
      <div className="w-full md:w-2/3 p-3 flex flex-col justify-between space-y-4">
        {/* Title Skeleton */}
        <div className="h-6 bg-gray-300 rounded w-3/4"></div>

        {/* Excerpt Skeleton */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-300 rounded w-full"></div>
          <div className="h-4 bg-gray-300 rounded w-5/6"></div>
        </div>

        {/* Provider Info and Date Skeleton */}
        <div className="flex items-center space-x-2">
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          <span className="h-4 bg-gray-300 rounded w-1"></span>
          <div className="h-4 bg-gray-300 rounded w-1/6"></div>
        </div>

        {/* Tags Skeleton */}
        <div className="flex space-x-2 mt-2">
          {[1, 2, 3].map((_, index) => (
            <div
              key={index}
              className="bg-gray-300 rounded-full h-6 w-16"
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};
