import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { SiteHeader } from "@/routes/site-header";

import { Footer } from "@/routes/footer";
import { LoginOverlay } from "./login-overlay";
import { LoadingPost } from "@/routes/loading";
import { API_URL, NO_IMAGE_URL, SOCKET_URL, getRandomImage } from "@/config";
import { useNavigate } from "react-router-dom";
import { ErrorPage } from "@/routes/error";

import {
  Terminal,
  BookmarkPlusIcon,
  BookmarkMinusIcon,
  Icon,
  Smartphone,
} from "lucide-react";
import { Icons } from "@/components/ui/icon";
import io from "socket.io-client";
import {
  generateUniqueId,
  getHeader,
  getToken,
  removeAccessToken,
} from "@/routes/utils";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Navbar } from "./navbar";

export function PurchasesArticleDialog({
  article_price,
  original_url,
  open,
  showModal,
  purchasesArticle,
  loading = false,
}) {
  const { slug } = useParams();
  const [encodedSlug, setEncodedSlug] = useState("");

  // Encode the slug when the component loads
  useEffect(() => {
    if (slug) {
      setEncodedSlug(btoa(slug));
    }
  }, [slug]);
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Do you want to purchase this article?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This article costs <b>${article_price || `0.29`}</b>.
            <br />
            Once your total spending reaches about <b>$10</b>, a transaction
            will be initiated.
            <br />
            Once you purchase this article you can read it anytime.
            <br />
            <a
              href={`/article/purchases-policy/?ref=${encodedSlug}`}
              className="text-blue-500 underline"
            >
              Read More
            </a>
            <br />
            <span>
              Read on the {` `}
              <a
                target="_blank"
                href={original_url}
                className="text-blue-500 underline"
              >
                original site
              </a>
            </span>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!loading && (
            <Button
              onClick={() => {
                showModal(false);
              }}
              variant="outline"
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={purchasesArticle}
            className={loading ? "" : "bg-info"}
            variant="outline"
            size={loading ? "icon" : ""}
            disabled={loading}
          >
            {loading ? (
              <div className="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              "Continue"
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function AlertFreeUser() {
  return (
    <Alert>
      <Terminal className="h-4 w-4" />
      <AlertTitle>Heads up!</AlertTitle>
      <AlertDescription>
        <b> You will charge one time 0.29$ for each paid article.</b>
      </AlertDescription>
    </Alert>
  );
}

function wrapTextWithDivs(text) {
  if (!text) return "";

  // Split the text by newlines
  let lines = text.split("\n");

  // Wrap each non-empty line with a <div> tag
  let wrappedLines = lines
    .filter((line) => line.trim() !== "")
    .map((line, index) => (
      <div style={{ marginBottom: "1rem" }} key={index}>
        {line}
      </div>
    ));

  return wrappedLines;
}

function ltomap(data) {
  const after_at = {};

  // Iterate over the data array
  data.forEach((item) => {
    const key = item.after_at;

    // Initialize the object for the key if it does not exist
    if (!after_at[key]) {
      after_at[key] = {
        image: [],
        caption: [],
      };
    }

    // Push the featured_image and caption to the respective arrays
    after_at[key].image.push(item.featured_image);
    after_at[key].caption.push(item.caption);
  });
  return after_at;
}

function dangerousContent(line, index) {
  if (line.startsWith("<ul") || line.startsWith("<ol")) {
    return (
      <div
        id="no-safe-ul"
        key={index}
        style={{ marginBottom: "1rem", listStyleType: "circle" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (
    line.startsWith("<h1>") ||
    line.startsWith("<h2>") ||
    line.startsWith("<h3>") ||
    line.startsWith("<h4>")
  ) {
    return (
      <div
        id="no-safe-h"
        key={index}
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (line.startsWith('<div class="flourish-embed flourish-chart"')) {
    const flourish = "flourish-embed";
    if (!document.getElementById(flourish)) {
      const script = document.createElement("script");
      script.id = flourish;
      script.src = "https://public.flourish.studio/resources/embed.js"; // Twitter embed script
      script.async = true;
      document.body.appendChild(script);
    }
    return (
      <div
        key={index}
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (line.startsWith('<blockquote class="twitter-tweet">')) {
    const twitterScriptId = "twitter-widgets-script";
    if (!document.getElementById(twitterScriptId)) {
      const script = document.createElement("script");
      script.id = twitterScriptId;
      script.src = "https://platform.twitter.com/widgets.js"; // Twitter embed script
      script.async = true;
      document.body.appendChild(script);
    }
    return (
      <div
        key={index}
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (line.startsWith("<blockquote ")) {
    return (
      <div
        key={index}
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (line.includes("<a ")) {
    return (
      <div
        key={index}
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (line.includes("<audio ")) {
    return (
      <div
        key={index}
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else if (line.includes("<iframe ")) {
    return (
      <div
        key={index}
        className="w-full h-[500px] md:h-[400px] sm:h-[300px]"
        style={{ marginBottom: "1rem" }}
        dangerouslySetInnerHTML={{ __html: line }}
      />
    );
  } else {
    return (
      <div style={{ marginBottom: "1rem" }} key={index}>
        {line}
      </div>
    );
  }
}

function extractAndWrapQuotes(text, afterAt = [], image = null) {
  if (!text) return "";
  text = text.replace(/\u201C/g, '"');
  text = text.replace(/\u201D/g, '"');

  // Regular expression to find quotes with author
  const quoteRegex = /(['“"])(.+?)\1(.+?)(\.)/g;

  // Find all quotes
  const quotes = text.match(quoteRegex) || [];

  function getSmallestAfterAtKey(afterAt) {
    if (!afterAt || afterAt.length === 0) return null;

    // Find the object with the smallest after_at value
    const smallest = afterAt.reduce((prev, current) => {
      return prev.after_at < current.after_at ? prev : current;
    });

    return smallest;
  }
  const smallestAfterAt = getSmallestAfterAtKey(afterAt);
  let lines = text.split("\n").filter((line) => line.trim() !== "");
  let wrappedLines = lines.map((line, index) => {
    const after_at = ltomap(afterAt);
    console.log(image);
    if (smallestAfterAt && after_at && !image) {
      after_at[smallestAfterAt.after_at] = null;
    }

    let cl = dangerousContent(line, index);
    return (
      <>
        {cl}
        {after_at[index] &&
          after_at[index].image.map((imgSrc, imgIndex) => (
            <div
              key={imgIndex}
              style={{ marginBottom: "1rem" }}
              className="fbt-item-thumbnail radius-10"
            >
              <figure>
                <img
                  className="post-thumbnail lazyloaded"
                  src={imgSrc}
                  alt={after_at[index].caption[imgIndex] || "Image"}
                />
                {after_at[index].caption[imgIndex] && (
                  <em>
                    <figcaption
                      style={{
                        fontStyle: "italic",
                        color: "gray",
                        textAlign: "center",
                        marginTop: "0.5rem",
                      }}
                    >
                      {after_at[index].caption[imgIndex]}
                    </figcaption>
                  </em>
                )}
              </figure>
            </div>
          ))}
      </>
    );
  });

  return [...wrappedLines];
}

const WrappedTextComponent = ({ text, afterAt = [], image = null }) => {
  const wrappedLines = extractAndWrapQuotes(text, afterAt, image);

  return <div>{wrappedLines}</div>;
};

export const SinglePost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [msgCode, setMSGCode] = useState(null);
  const [extaData, setExtraData] = useState({});
  const [purchasesModal, setPurchasesModal] = useState(false);
  const [purchasesLoading, setPurchasesLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [timer, setTimer] = useState(false);
  const [bookmarked, setBookMarked] = useState(false);
  const [bookmarkedLoading, setBookmarkedLoading] = useState(false);
  const init = useRef({ done: false, connected: false });
  const socketRef = useRef(null);
  const startTimeRef = useRef(Date.now());
  const authToken = getToken();
  const uuid = generateUniqueId();
  const token = getToken();

  const [title, setTitle] = useState("Scrollne.ws: Clever TK TK!");
  const navigate = useNavigate();
  localStorage.setItem("ldirect", window.location.pathname);

  const [isLogin, setIsLogin] = useState(false);
  const [startTime] = useState(Date.now());

  useEffect(() => {
    if (user && post) {
      startTimeRef.current = Date.now();
      // Ensure user is updated before logging
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log("Disconnected from previous socket");
      }
      socketRef.current = io(SOCKET_URL, {
        query: { slug }, // Send the article slug in the query
        transports: ["websocket"],
        reconnection: true, // Enable reconnection attempts
        reconnectionAttempts: 5, // Set the maximum number of reconnection attempts
        reconnectionDelay: 1000, // Delay between reconnection attempts in milliseconds (optional)
        reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts in milliseconds (optional)
      });

      socketRef.current.on("connect", () => {});

      // Handle connection errors
      socketRef.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });

      // Handle reconnection failure
      socketRef.current.on("reconnect_failed", () => {
        console.error("Reconnection attempts failed");
      });

      const intervalId = setInterval(() => {
        const readTime = Math.floor((Date.now() - startTimeRef.current) / 1000); // Calculate reading time
        socketRef.current.emit("updateReadTime", {
          reference: uuid,
          article_slug: slug,
          user: user.alias,
          token: authToken,
          slug,
          max_read_time: readTime,
        });
      }, 1000);

      return () => {
        clearInterval(intervalId); // Clear the interval
        if (socketRef.current) {
          const totalReadTime = Math.floor(
            (Date.now() - startTime.current) / 1000
          );
          socketRef.current.emit("userDisconnected", {
            reference: uuid,
            article_slug: slug,
            user: user.alias,
            token: authToken,
            slug,
            max_read_time: totalReadTime,
          });
          socketRef.current.disconnect(); // Disconnect the socket
        }
      };
    }
  }, [user, slug, post]);

  useEffect(() => {
    const fetchUser = async () => {
      if (!init.current.done) {
        try {
          let header = getHeader();
          const token = getToken();
          const response = await axios.get(
            `${API_URL}/api/v1/users/me/`,
            header
          );
          setUser(response.data);
          init.current.done = true;
        } catch (error) {
          if (error.response.status == 401) {
            removeAccessToken();
            navigate("/account/");
          }
        }
      }
    };
    if (authToken) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    let hasSentTimeSpent = false;
    const sendTimeSpent = () => {
      if (hasSentTimeSpent) return;
      if (socketRef.current) {
        const readTime = Math.floor((Date.now() - startTimeRef.current) / 1000);
        socketRef.current.emit("userDisconnected", {
          reference: uuid,
          article_slug: slug,
          user: user.alias,
          token: authToken,
          slug,
          max_read_time: readTime,
        });
      }

      const endTime = Date.now();
      const totalTimeSpent = Math.round((endTime - startTime) / 1000);
      const token = getToken();
      const data = JSON.stringify({
        total_read_time: totalTimeSpent,
        token: token,
      });

      hasSentTimeSpent = true;
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        sendTimeSpent();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", sendTimeSpent);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", sendTimeSpent);
    };
  }, [API_URL, slug, startTime]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const titleize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const token = getToken();
        let header = getHeader();
        const response = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        if (response.data.feeds.length == 0) {
          navigate("/feeds/");
        }
        setSubscription(response.data);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, [navigate]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const auth_token = getToken();

        const headers = getHeader();

        const response = await axios.get(
          `${API_URL}/api/v1/news/${slug}/`,
          headers
        );

        setPost(response.data);
        setTitle(
          "Scrollne.ws | " +
            response.data.feeder.name +
            " | " +
            response.data.title
        );
        setTimer(true);
        setBookMarked(response.data.bookmarked);
      } catch (error) {
        if (error.response.status === 404) {
          setError("Could not found the POST!");
          return;
        }

        if (
          error.response.status === 403 &&
          error.response.data.code === 40100
        ) {
          setMSGCode(error.response.data.code);
          setExtraData(error.response.data.data);
          navigate("/pricing/");
        } else if (
          error.response.status === 403 &&
          error.response.data.code === 40109
        ) {
          setExtraData(error.response.data.data);
          setPurchasesModal(true);
          setMSGCode(error.response.data.code);
          setError("You are not subscribed to this feed!");
        }
        if (error.response && error.response.status === 401) {
          removeAccessToken();
          navigate("/account/");
          setIsLogin(false); // User is not logged in or token is invalid
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  const handlePurchasesArticle = async () => {
    setPurchasesLoading(true);
    try {
      const token = getToken();
      let headers = getHeader();
      const response = await axios.post(
        `${API_URL}/api/v1/news/${slug}/purchase/`,
        {},
        headers
      );
      setPost(response.data.data.article);
      setTitle(
        "Scrollne.ws | " +
          response.data.feeder.name +
          " | " +
          response.data.data.article.title
      );
    } catch (error) {
    } finally {
      setPurchasesLoading(false);
    }
  };

  const handleBookMark = () => {
    setBookmarkedLoading(true);
    const updateBookMark = async () => {
      try {
        const token = getToken();
        let header = getHeader();
        const response = await axios.post(
          `${API_URL}/api/v1/news/${slug}/book-mark/`,
          {},
          header
        );
      } catch (error) {
      } finally {
        setBookmarkedLoading(false);
      }
      setBookMarked((prev) => !prev);
    };
    updateBookMark();
  };

  if (loading && !post)
    return (
      <>
        {token ? (
          <Navbar firehouse={false} setFilterConfig={null} post={true} />
        ) : (
          <SiteHeader />
        )}
        <LoadingPost></LoadingPost>
        <Footer></Footer>
      </>
    );
  if (error && !post) {
    return (
      <>
        {msgCode === 40109 && (
          <PurchasesArticleDialog
            article_price={extaData.article_price}
            original_url={extaData.original_url}
            open={purchasesModal}
            showModal={setPurchasesModal}
            purchasesArticle={handlePurchasesArticle}
            loading={purchasesLoading}
          />
        )}

        <div className="outer-wrapper clearfix" id="outer-wrapper">
          <div className="container fbt-elastic-container">
            <div className="row justify-content-center">
              <ErrorPage
                extra={setPurchasesModal}
                showBuffer={error.length < 36}
                h1={`${error}`}
              />
            </div>
          </div>
        </div>
        <div className="py-3"></div>
        <Footer></Footer>
      </>
    );
  }
  const getImageUrl = (post, relatedImages) => {
    if (post.resized_featured_image) {
      return post.resized_featured_image;
    } else {
      const sortedImages = relatedImages.sort(
        (a, b) => a.after_at - b.after_at
      );
      const smallestAfterImage = sortedImages[0];
      return smallestAfterImage
        ? smallestAfterImage.featured_image
        : getRandomImage();
    }
  };

  const getCaption = (post, relatedImages) => {
    // If image is from resized_featured_image, return the post caption
    if (post.resized_featured_image) {
      return post.caption && post.caption.length > 0
        ? post.caption
        : "No Caption";
    } else {
      // If image is from related images, return the caption from the image with the smallest 'after_at'
      const sortedImages = relatedImages.sort(
        (a, b) => a.after_at - b.after_at
      ); // Sort by smallest 'after_at'
      const smallestAfterImage = sortedImages[0];
      return smallestAfterImage
        ? smallestAfterImage.caption || "No Caption"
        : "No Caption";
    }
  };
  if(post){
    localStorage.removeItem("ldirect")
  }

  return (
    <>
      {token ? (
        <Navbar firehouse={false} setFilterConfig={null} />
      ) : (
        <SiteHeader />
      )}
      <div
        className="outer-wrapper clearfix"
        id="outer-wrapper"
        style={{ filter: isLogin ? "blur(5px)" : "none" }}
      >
        <div className="container fbt-elastic-container">
          <div className="row justify-content-center">
            <div className="fbt-main-wrapper col-xl-12">
              <div id="main-wrapper">
                <div className="main-section" id="main_content">
                  <div className="blog-posts fbt-item-post-wrap">
                    <div className={`blog-post fbt-item-post`}>
                      <div style={{ marginTop: "65px" }} className="">
                        <div className="row align-items-center slider-width"></div>
                      </div>

                      <div className="row justify-content-center mt-3">
                        <div className="col-xl-9 col-lg-8">
                          <div className="mt-n5">
                            <div
                              style={{ fontSize: "16px" }}
                              className="post-body post-content"
                            >
                              {post.resized_featured_image && (
                                <div className="col-lg-12">
                                  <div className="fbt-shape-container">
                                    <div className="fbt-item-thumbnail radius-10">
                                      <figure className="">
                                        <img
                                          alt=""
                                          className="post-thumbnail lazyloaded"
                                          src={getImageUrl(
                                            post,
                                            post.after_at || []
                                          )}
                                        />
                                        {getCaption(post, post.after_at) && (
                                          <>
                                            <figcaption>
                                              {getCaption(post, post.after_at)}
                                            </figcaption>
                                          </>
                                        )}
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-12 mt-4 mt-lg-0 justify-content-center">
                                <div
                                  style={{ margin: "20px 0" }}
                                  className="fbt-shape-title"
                                >
                                  <h1 className="post-title display-4">
                                    {post.title}
                                  </h1>
                                  {post.author && (
                                    <p className="post-author mt-2">
                                      <span className="text-blue-500 font-bold underline cursor-pointer hover:text-blue-700">
                                        {post.author.name}
                                      </span>
                                    </p>
                                  )}

                                  <div className="item-post-meta mt-4">
                                    <div className="post-meta d-flex align-items-center">
                                      <span className="post-author mr-3">
                                        <a
                                          href={`/provider/${post.feeder.slug}/`}
                                          title="fbtemplates"
                                        >
                                          {post.feeder.display_name ||
                                            post.feeder.name}
                                        </a>
                                      </span>
                                      <span className="post-date published mr-3">
                                        {post.pub_date}
                                        {/* Format date as needed */}
                                      </span>
                                      {bookmarkedLoading ? (
                                        <Icons.spin />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            handleBookMark();
                                          }}
                                          className="pointer"
                                        >
                                          {bookmarked ? (
                                            <BookmarkMinusIcon />
                                          ) : (
                                            <BookmarkPlusIcon />
                                          )}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <WrappedTextComponent
                                text={post.content}
                                afterAt={post.after_at}
                                image=""
                              />
                              {/* <iframe
                                className="w-full h-full"
                                src="https://datawrapper.dwcdn.net/xyO82/1/"
                                style={{ border: 0 }}
                              ></iframe> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="post-footer">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-9">
                            <div className="row align-items-center my-4">
                              <div className="col-lg-8 text-center text-lg-left mb-3 mb-lg-0">
                                <div className="post-labels">
                                  <span className="mr-2">Tags:</span>
                                  <span className="label-head Label">
                                    {post &&
                                      post.tags &&
                                      post.tags.map((tagItems, index) => {
                                        return (
                                          <a
                                            key={index}
                                            style={{ margin: "1px" }}
                                            className="label-link badge badge-info py-1 px-3"
                                            href={`/tags/${tagItems.slug}/`}
                                          >
                                            {titleize(tagItems.title)}
                                          </a>
                                        );
                                      })}
                                  </span>
                                </div>
                                <div className="post-labels py-2">
                                  <a
                                    target="_blank"
                                    href={
                                      post.original_url
                                        ? post.original_url
                                        : "#"
                                    }
                                    className="text-blue-700 underline"
                                  >
                                    View on original site
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="fbt-item-post-pager">
                          <div className="card shadow-lg radius-10 mt-3 mb-5">
                            <div className="post-pager row">
                              {post.previous_article && (
                                <div className="previous col-lg-6 bg-info px-5 py-5 text-left">
                                  <a
                                    className="fbt-newer-link text-white"
                                    href={`/posts/${post.previous_article.slug}/`}
                                  >
                                    <strong className="lead text-left pl-3">
                                      Previous
                                    </strong>
                                    <div className="h2 text-white fbt-np-title mt-2 pl-3">
                                      {post.previous_article.title}
                                    </div>
                                    {post.next_article && (
                                      <div className="lead text-left pl-3 mt-2">
                                        {post.next_article.feeder.toUpperCase()}{" "}
                                        - {post.next_article.pub_date}
                                      </div>
                                    )}
                                  </a>
                                </div>
                              )}
                              {post.next_article && (
                                <div className="next col-lg-6 bg-warning px-5 py-5 text-right">
                                  <a
                                    className="fbt-older-link text-white"
                                    href={`/posts/${post.next_article.slug}/`}
                                  >
                                    <strong className="lead text-right pr-3">
                                      Next
                                    </strong>
                                    <div className="h2 text-white text-right fbt-np-title mt-2 pr-3">
                                      {post.next_article.title}
                                    </div>
                                    {post.next_article && (
                                      <div className="lead text-right pr-3 mt-2">
                                        {post.next_article.feeder.toUpperCase()}{" "}
                                        - {post.next_article.pub_date}
                                      </div>
                                    )}
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLogin && (
        <div
          className="inset-0 fixed flex w-full h-full items-center justify-center duration-300 transition-opacity"
          style={{ zIndex: 1000 }}
        >
          <div className="flex-col w-full">
            <LoginOverlay />
          </div>
        </div>
      )}
      <Footer></Footer>
    </>
  );
};
