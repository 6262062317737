import { MainNav } from "@/routes/heading";
import { SearchMenu } from "@/routes/search";
// import { UserNav } from "@/routes/user-nav";
import { Button } from "@/components/ui/button";

import { useNavigate } from "react-router-dom";

import {
  CreditCard,
  LifeBuoy,
  LogOut,
  Settings,
  CircleUser as User,
  UserRoundPlus,
  LogIn,
} from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { getToken, removeAccessToken } from "./utils";

export function DropDownMenuMe() {
  const navigate = useNavigate();
  const token = getToken();

  function handleLogout() {
    removeAccessToken()
    window.location.reload();
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="w-9 px-0">
          <User className="h-6 w-6" />
          <span className="sr-only">Me</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          {!token && (
            <>
              <DropdownMenuItem
                onClick={() => {
                  navigate("/login/");
                }}
                className="pointer"
              >
                <LogIn className="mr-2 h-4 w-4" />
                <span>Login</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  navigate("/account/");
                }}
                className="pointer"
              >
                <UserRoundPlus className="mr-2 h-4 w-4" />
                <span>Sign Up</span>
              </DropdownMenuItem>
            </>
          )}
          {token && (
            <>
              <DropdownMenuItem
                className="pointer"
                onClick={() => {
                  navigate("/dashboard/profile/");
                }}
              >
                <Settings className="mr-2 h-4 w-4" />
                <span>Settings</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  handleLogout();
                }}
                className="pointer"
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Logout</span>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="pointer">
          <LifeBuoy className="mr-2 h-4 w-4" />
          <span>Support</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function SiteHeader() {
  return (
    <>
      <header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <a
          href="/"
          className="md:hidden sm:hidden flex items-center justify-center  space-y-2"
        >
          <span className="font-bold">
            <img src="https://dev.scrollne.ws/logo_nemesis.png" />
          </span>
        </a>
        <div className="container flex h-14 max-w-screen-2xl items-center">
          <MainNav />
          {/* <MobileNav /> */}
          <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
            <div className="w-full flex-1 md:w-auto md:flex-none">
              <SearchMenu />
            </div>
            <nav className="flex items-center">
              <DropDownMenuMe></DropDownMenuMe>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
