import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "@/config";
import { useNavigate } from "react-router-dom";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Icons } from "@/components/ui/icon";
import { getHeader, getToken } from "../utils";

export function CancelSubscriptionConfirm({
  open,
  showModal,
  loading = false,
}) {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Do you want to cancel this subscription?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Pleae NOTE: <b>This action is irreversible.</b>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!loading && (
            <Button
              onClick={() => {
                showModal(false);
              }}
              variant="outline"
              className="bg-success"
            >
              Close
            </Button>
          )}

          <Button
            onClick={() => console.log("Cancel")}
            className={loading ? "" : "bg-danger"}
            variant="outline"
            size={loading ? "icon" : ""}
            disabled={loading}
          >
            {loading ? (
              <div className="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              "Cancel"
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function FreeSubscription({
  current = false,
  changable = false,
  loading = false,
  upgradePlan = null,
  subscription = null,
}) {
  return (
    <Card>
      <CardHeader>
        {current && (
          <CardTitle className="text-lg font-semibold">
            Current Subscription:
          </CardTitle>
        )}

        {changable && (
          <CardTitle className="text-lg font-semibold">
            Change Subscription to:
          </CardTitle>
        )}
        <CardDescription className="text-xl">FREE</CardDescription>
      </CardHeader>
      <CardContent>
        {/* <Button variant="outline" className="mt-2">Change Subscription</Button> */}
        <p className="mt-4 text-sm text-muted-foreground">
          Free subscription gives you access to every free and non-profit
          newsroom in our library.
        </p>

        {changable && subscription && subscription.alias && (
          <Button
            onClick={() => {
              upgradePlan("free");
            }}
            disabled={loading}
            variant="default"
            className="mt-4 bg-info"
          >
            Change Subscription
          </Button>
        )}
        {subscription && !subscription.alias && (
          <Button
            onClick={() => {
              upgradePlan("basic");
            }}
            disabled={loading}
            variant="default"
            className="mt-4 bg-info"
          >
            Subscribe
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export function BasicSubscription({
  current = false,
  changable = false,
  loading = false,
  upgradePlan = null,
  subscription = null,
}) {
  return (
    <Card className="mb-4">
      <CardHeader>
        {current && (
          <CardTitle className="text-lg font-semibold">
            Current Subscription:
          </CardTitle>
        )}

        {changable && (
          <CardTitle className="text-lg font-semibold">
            Change Subscription to:
          </CardTitle>
        )}
        <CardDescription className="text-xl">BASIC</CardDescription>
      </CardHeader>
      <CardContent>
        <p className="mt-2 text-sm text-muted-foreground">
          Free subscription gives you access to every free and non-profit
          newsroom in our library.
        </p>
        <p>
          Additionally, you get ad-free versions of a limited number of
          for-profit publications.
        </p>
        {changable && subscription && subscription.alias && (
          <Button
            onClick={() => {
              upgradePlan("basic");
            }}
            disabled={loading}
            variant="default"
            className="mt-4 bg-info"
          >
            Change Subscription
          </Button>
        )}
        {subscription && !subscription.alias && (
          <Button
            onClick={() => {
              upgradePlan("basic");
            }}
            disabled={loading}
            variant="default"
            className="mt-4 bg-info"
          >
            Subscribe
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export function PremimumSubscription({
  current = false,
  changable = false,
  loading = false,
  upgradePlan = null,
  subscription = null,
}) {
  return (
    <Card>
      <CardHeader>
        {current && (
          <CardTitle className="text-lg font-semibold">
            Current Subscription:
          </CardTitle>
        )}

        {changable && (
          <CardTitle className="text-lg font-semibold">
            Change Subscription to:
          </CardTitle>
        )}
        <CardDescription className="text-xl">PREMIUM</CardDescription>
      </CardHeader>
      <CardContent>
        <p className="mt-2 text-sm text-muted-foreground">
          Free subscription gives you access to every free and non-profit
          newsroom in our library.
        </p>
        <p>
          Additionally, you get ad-free versions of a limited number of
          for-profit publications.
        </p>

        {changable && subscription && subscription.alias && (
          <Button
            onClick={() => {
              upgradePlan("premium");
            }}
            disabled={loading}
            variant="default"
            className="mt-4 bg-info"
          >
            Change Subscription
          </Button>
        )}
        {subscription && !subscription.alias && (
          <Button
            onClick={() => {
              upgradePlan("basic");
            }}
            disabled={loading}
            variant="default"
            className="mt-4 bg-info"
          >
            Subscribe
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export function SubscriptionPageData() {
  const [subscription, setSubscription] = useState(null);
  const [loading, isLoading] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [payments, setPayments] = useState(false);
  const token = getToken()

  const fetchPayments = async () => {
    let header = getHeader()
    try {
      const response = await axios.get(`${API_URL}/api/v1/payments/`, header);
      setPayments(response.data)
    } catch (error) {
      console.log(error)
    } finally {
    }
  };

  const fetchSubscription = async () => {
    let header = getHeader()
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/payments/subscription/`,
        {},
        header
      );
      setSubscription(response.data);
      isLoading(false);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    isLoading(true);
    fetchSubscription();
    fetchPayments()
  }, []);

  const handleUpgrade = async (plan) => {
    const upgrade = async (plan) => {
      isLoading(true);
      let header = getHeader()
      try {
        const response = await axios.post(
          `${API_URL}/api/v1/payments/subscription/upgrade/`,
          {
            plan,
            session: subscription.stripe_checkout_session_id,
          },
          header
        );
        if (response.data.code == 40001) {
          fetchSubscription();
        }
        isLoading(false);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      } finally {
        isLoading(false);
      }
    };
    const subscribe = async () => {
      isLoading(true);
      let header = getHeader()
      try {
        const response = await axios.post(
          `${API_URL}/api/v1/payments/init/`,
          {
            subscription_id: plan,
          },
          header
        );
        window.location.href = response.data.redirect_url
        isLoading(false);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      } finally {
        isLoading(false);
      }
    };
    if (subscription && subscription.alias) {
      upgrade(plan);
    } else {
      subscribe();
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <CancelSubscriptionConfirm
        open={confirmCancel}
        showModal={setConfirmCancel}
        loading={loading}
      />
      {((subscription && !subscription.alias) || !subscription) && (
        <div
          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
          role="alert"
        >
          <p className="font-bold">Notice</p>
          <p>
            Your subscription has ended. Please{" "}
            <button className="text-blue-600 underline hover:text-blue-800">
              re-subscribe
            </button>{" "}
            to continue.
          </p>
        </div>
      )}

      <Separator className="my-6" />
      <FreeSubscription
        current={subscription && subscription.plan.name.toLowerCase() == "free"}
        changable={
          subscription && subscription.plan.name.toLowerCase() != "free"
        }
        loading={loading}
        upgradePlan={handleUpgrade}
        subscription={subscription}
      />

      <Separator className="my-6" />
      <BasicSubscription
        current={
          subscription && subscription.plan.name.toLowerCase() == "basic"
        }
        changable={
          subscription && subscription.plan.name.toLowerCase() != "basic"
        }
        loading={loading}
        upgradePlan={handleUpgrade}
        subscription={subscription}
      />

      <Separator className="my-6" />
      <PremimumSubscription
        current={
          subscription && subscription.plan.name.toLowerCase() == "premium"
        }
        changable={
          subscription && subscription.plan.name.toLowerCase() != "premium"
        }
        loading={loading}
        upgradePlan={handleUpgrade}
        subscription={subscription}
      />
      {subscription && subscription.alias && (
        <>
          <div className="text-center text-sm text-muted-foreground mt-6">
            <p>
              We're not going to bury this someplace you can't find it or make
              you call some phone support tree that makes you waste 13 minutes
              before getting to a menu that lets you quit. But what we're doing
              here is really important and we would like you to be a part of it.
              So please think hard about it before clicking below. <br />
              Thanks.
            </p>
            <Button
              onClick={() => {
                setConfirmCancel(true);
              }}
              variant="default"
              className="mt-4 bg-danger"
            >
              Cancel Subscription
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export function SubscriptionPage() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Subscription</h3>
        <p className="text-sm text-muted-foreground">
          Configure how you subscription.
        </p>
      </div>
      <Separator />
      <SubscriptionPageData />
    </div>
  );
}
