"use client";

// eslint-disable-next-line no-unused-vars
import * as React from "react";
// import a from "next/a";
// import { usePathname } from "next/navigation"

// import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";

import { Icons } from "@/components/ui/icon";
import { getToken, removeAccessToken } from "./utils";

export function LogLogButton() {
  let token = getToken();
  const handleLogout = () => {
    removeAccessToken()
    window.location.reload();
  }
  if(token){
    return (
      <a
        onClick={() => handleLogout()}
        href="#"
        className={cn(
          "transition-colors hover:text-foreground/80",
          window.location.pathname?.startsWith("/account/")
            ? "text-foreground"
            : "text-foreground/60"
        )}
      >
        Logout
      </a>
    );
  }

  return (
    <a
      href="/account/"
      className={cn(
        "transition-colors hover:text-foreground/80",
        window.location.pathname?.startsWith("/account/")
          ? "text-foreground"
          : "text-foreground/60"
      )}
    >
      Account
    </a>
  );
}

export function MainNav() {
  //   const pathname = usePathname()
  let pathname = window.location.pathname;
  let token = getToken();

  return (
    <div className="mr-4 md:flex">
      <a href="/" className="mr-6 flex items-center space-x-2">
        <span className="hidden font-bold sm:inline-block">
          <img src="https://dev.scrollne.ws/logo_nemesis.png" />
        </span>
      </a>
      <nav  className="flex items-center gap-4 text-sm lg:gap-6 md:hidden sm:hidden flex items-center justify-center  space-y-2">
        <a href="/">
        <Icons.home className="mb-3 h-6 w-6"  />
        </a>
      </nav>
    </div>
  );
}

export function MainNav1() {
  return (
    <div id="page-wrapper" class="full-page item-view">
      <nav className="navbar navbar-expand-xl navbar-fbt fbt-nav-skin fbt_sticky_nav">
        <div className="container nav-mobile-px clearfix">
          <div className="navbar-brand order-2 order-xl-1 m-auto">
            <a href="./index-2.html">
              <img src="https://dev.scrollne.ws/logo_nemesis.png" />
            </a>
          </div>
          <button
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler order-1 order-xl-2"
            data-target="#navbar-menu"
            data-toggle="collapse"
            type="button"
          >
            ☰
          </button>
          <div className="header-buttons order-3 order-lg-4">
            <span className="fa fa-search navbar-search search-trigger"></span>
            <span className="fbt-sidenav ml-1 active" onclick="openNav()">
              ☰
            </span>
          </div>
          <div
            className="collapse navbar-collapse order-4 order-xl-3 clearfix"
            id="navbar-menu"
          >
            <ul className="navbar-nav m-auto clearfix">
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-toggle="dropdown"
                >
                  Home
                </a>
                <div className="dropdown-menu">
                  <a href="./index.html" className="dropdown-item">
                    Home 1
                  </a>
                  <a href="./index-2.html" className="dropdown-item">
                    Home 2
                  </a>
                  <a href="./index-3.html" className="dropdown-item">
                    Home 3
                  </a>
                  <a href="./index-4.html" className="dropdown-item">
                    Home 4
                  </a>
                  <a href="./index-5.html" className="dropdown-item">
                    Home 5
                  </a>
                  <a href="./blog.html" className="dropdown-item">
                    Home 6
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a href="./contact.html" className="nav-link">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Sport
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Policy
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-toggle="dropdown"
                >
                  Features
                </a>
                <div className="dropdown-menu">
                  <a href="#" className="dropdown-item">
                    Feature 1
                  </a>
                  <a href="#" className="dropdown-item">
                    Feature 2
                  </a>
                  <a href="#" className="dropdown-item">
                    Feature 3
                  </a>
                  <a href="#" className="dropdown-item">
                    Feature 5
                  </a>
                  <a href="#" className="dropdown-item">
                    Feature 6
                  </a>
                  <a href="#" className="dropdown-item">
                    Feature 7
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a href="./error.html" className="nav-link">
                  Error Page
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Lifestyle
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
